import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ApplicationService } from '@app/applications/application.service';
import { MatSnackBar } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { OnDestroy } from '@angular/core';
import { ValidationService } from '@app/applications/application-validators/validation.service';
import { Applicant } from '@app/applications/application-details/collateral/collateralDetails/collateral-details.model';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import { DomSanitizer } from '@angular/platform-browser';
import { ApplicantInformations, PersonProfile } from '@app/applications/application-details/applicant/applicantInformation/applicant-information.model';
import { Person } from '@app/applications/applications.model';
import { Address, AddressLink, ApplicantAddress } from '@app/applications/application-details/applicant/applicantAddress/applicant-address.model';
import { ConfirmationDialogService } from '@app/utils/confirmation-dialog/confirmation-dialog.service';
import { KycProof } from '@app/applications/application-details/business/kycProofs/kyc.model';
import { ReferenceCodeService } from '@app/admin/reference-code/reference-code.service';
import { NameValueDto } from '@app/loan-od-accounts/name-value-dto';
import { KYC_VERIFICATION_MODE_ELECTRONIC, SOURCE_DIGITAL, SOURCE_MANUAL } from '@app/constants/data.constants';
@Component({
  selector: 'eng-digilocker',
  templateUrl: 'ekyc.template.html',
  styleUrls: ['../../../application-details.styles.scss', '../xdata-service.style.scss']
})
export class EkycComponent implements OnInit, OnDestroy {

  applicationUid: string;
  xDataResponse:any = {};
  url :any;
  applicantImage:any;
  borrowerDto: ApplicantInformations =
    {
      "person": new Person(),
      "personProfile": new PersonProfile(),
      "personAddressInformations": [],
    }

  titles: NameValueDto[] = [];
  genders: NameValueDto[] = [];
  relationshipToBusinesses: NameValueDto[] = [];
  addressTypes: NameValueDto[];
  countryList: NameValueDto[];
  stateList: NameValueDto[];
  districtList: NameValueDto[];
  cityList: NameValueDto[];
  coapplicantForm: FormGroup = new FormGroup({});
  XDataForm: FormGroup = new FormGroup({});
  applicants: Applicant[];
  requestId:string;
  partyPlay: string = "coapplicant";
  showErrorCard: boolean;
  // this is used to enable or disable save button based on acceptanceCriteriaMet 
  isSaveEnabled: boolean;
  // if data is retrieved then we are not allowing image to edit or delete, hence we are using this boolean
  diableImageEditAndDelete: boolean;


  constructor(private formBuilder: FormBuilder,
    private applicationService: ApplicationService,
    private validationService: ValidationService,
    private _snackbar: MatSnackBar,
    private _sanitizer: DomSanitizer,
    private router: Router,
    private referenceCodeService: ReferenceCodeService,
    private confirmationDialogService:ConfirmationDialogService,
    private activatedRoute: ActivatedRoute) {
    this.activatedRoute.queryParams
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe((params) => {
        this.applicationUid = params.applicationUid;
    });
  }

  ngOnInit() {
    this.isSaveEnabled = true;
    this.diableImageEditAndDelete = false;
    this.getRefCodes();
    this.coapplicantForm = this.formBuilder.group({
      relationshipToBusiness: '',
      title:'',
      fName: '',
      mName:'',
      dob: '',
      age: null,
      lName:'',
      gender: '',
      address:'',
      addressType:'',
      firstLine:'',
      secondLine:'',
      thirdLine:'',
      pinCode:'',
      country:'',
      state:'',
      district:'',
      city:'',
      landmark:''
    });
    this.XDataForm = this.formBuilder.group({
      mobileNumber: '',
      email:'',
      name:''
    });
    this.applyValidatorsToCoApplicantAddingForm();
  }

  getRefCodes(){
  this.referenceCodeService.getShortRefCodes('title').subscribe((response: any) => {
    this.titles = response.title;
  });
  this.referenceCodeService.getShortRefCodes('Gender').subscribe((response: any) => {
    this.genders = response.Gender;
  });
  this.relationshipToBusinesses=[]
  this.referenceCodeService.getShortRefCodes('coApplicant_type').subscribe((response: any) => {
    this.relationshipToBusinesses = response.coApplicant_type;
  });
  this.referenceCodeService.getShortRefCodes('person_address_type').subscribe((response: any) => {
    this.addressTypes = response.person_address_type;
  });
  this.referenceCodeService.getShortRefCodes('Country').subscribe((response: any) => {
    this.countryList = response.Country;
  });
  this.referenceCodeService.getRefCodesForClassifier('State').subscribe((response: any) => {
    this.stateList = response;
  });
  this.referenceCodeService.getRefCodesForClassifier('District').subscribe((response: any) => {
    this.districtList = response;
  });
  this.referenceCodeService.getRefCodesForClassifier('City').subscribe((response: any) => {
    this.cityList = response;
  });
}
  onXDataServiceSelect(){
    this.applicationService.getApplicants(this.applicationUid).subscribe((response: any) => {
      if (response) {
        this.applicants = response;
      }
    })
  }

  verifyData() {
    this.validationService.markFormGroupTouched(this.XDataForm)
    if (this.XDataForm.valid) {
      this.applicationService.eKycRequest(
        this.applicationUid, this.XDataForm.value).subscribe((response) => {
          this.requestId = response.body.requestId;
        });
    } else {
      this._snackbar.open("Please Enter valid details", "Close", {
        duration: 5000,
      });
    }
  }

  // permanent address can be any address
  permanentAddress: ApplicantAddress = new ApplicantAddress(new Address, new AddressLink);
  
  retrieveData(){
    this.showErrorCard = false;
    this.diableImageEditAndDelete = false;
    this.applicationService.eKycDataRetrieve(
      this.applicationUid, this.requestId, this.partyPlay).subscribe((response) => {
        
        this.xDataResponse = response.body


        if (this.xDataResponse.status === "PENDING" ) {
          this._snackbar.open("KYC process is in progress. Please try after some time", "Close", {
            duration: 5000,
          });
        }else{
          this.xDataResponse.xdataPerson['fullNameArray']=this.xDataResponse.xdataPerson.fullName.split(' ')
          // if there is any validationMessage then only we are showing the error card
          if(this.xDataResponse.validationDetailDTO.validationMessages && this.xDataResponse.validationDetailDTO.validationMessages.length > 0){
            this.showErrorCard = true;
          }
          if(!this.xDataResponse.validationDetailDTO.acceptanceCriteriaMet){
            this.isSaveEnabled = false;
          }
          this.buildCoApplicantForm(this.xDataResponse)
          // let imageBase64String= btoa(this.xDataResponse.xdataPerson.imageData);
          // this.url = 'data:image/jpeg;base64,' + imageBase64String;
          this.url = this._sanitizer.bypassSecurityTrustResourceUrl('data:image/jpg;base64,' 
                 + this.xDataResponse.xdataPerson.imageData);
                 
                 this.applicantImage=[]
          let fileBlob=this.dataURItoBlob('data:image/jpeg;base64,' 
          + this.xDataResponse.xdataPerson.imageData)
          this.applicantImage.push( this.blobToFile(fileBlob, "ekyc- "+this.xDataResponse.xdataPerson.fullName+" -image.jpeg")) 
        }
      })
}

buildCoApplicantForm(xDataResponse) {
  // based on address type we are displaying the address fields 
  var applicantAddress: any = xDataResponse.xdataPersonAddress.permanentAddress

    this.coapplicantForm.patchValue({
      title: xDataResponse.xdataPerson.title,
      fName: xDataResponse.xdataPerson.firstName?xDataResponse.xdataPerson.firstName:xDataResponse.xdataPerson.fullNameArray[0],
      nName: xDataResponse.xdataPerson.aliasName,
      mName: xDataResponse.xdataPerson.middleName,
      dob: xDataResponse.xdataPerson.dob === null ?
        undefined : new Date(xDataResponse.xdataPerson.dob),
      age: xDataResponse.xdataPerson.age ? xDataResponse.xdataPerson.age : null,
      lName: xDataResponse.xdataPerson.lastName ? xDataResponse.xdataPerson.lastName:xDataResponse.xdataPerson.fullNameArray[1],
      gender: xDataResponse.xdataPerson.gender,
      addressType: applicantAddress.addressType,
      firstLine: applicantAddress.addrLine1,
      secondLine: applicantAddress.addrLine2,
      thirdLine: applicantAddress.addrLine3,
      pinCode: applicantAddress.pinCode,
      country: '',
      state: '',
      district: '',
      city:'' ,
      landmark:''
  })
  this.coapplicantForm.controls.dob.disable();
  this.coapplicantForm.controls.gender.disable();
  this.coapplicantForm.controls.fName.disable();
  this.coapplicantForm.controls.mName.disable();
  this.coapplicantForm.controls.lName.disable();
  this.diableImageEditAndDelete = true;
}

//this function will be executed by clicking save in "NEW COAPPLICANT" card
saveCoApplicant(){
  this.validationService.markFormGroupTouched(this.coapplicantForm)
  if(this.coapplicantForm.valid && this.isSaveEnabled){

  if(this.applicantImage){
      this.confirmationDialogService.open({ "btnCancelText": "NO", "btnConfirmText": "YES", "message": "Are you sure you want to Add Co-Applicant?" })
      this.confirmationDialogService.confirmed().subscribe(data => {
        if (data) {
          this.savePersonDetails();
          this.permanentAddress.addressLink.sameAsFlag = false;

          this.applicationService.addnewCoapplicant(
            this.applicationUid,0, this.applicantImage, this.borrowerDto ,this.partyPlay).subscribe((response) => {
              if(response.type != 0){
                this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
                  this.router.navigate(['/applications',this.applicationUid]);
              }); 
                this._snackbar.open("Co-Applicant added successfully", "Close", {
                  duration: 5000,
                });
              }
         });
        }
      })
    }else{
      this._snackbar.open("Please add Photo", "Close", {
        duration: 5000,
      });
    }
  }else{
    this._snackbar.open("Please Enter valid details", "Close", {
      duration: 5000,
    });
  }
}

savePersonDetails(){
  this.borrowerDto.person.title = this.coapplicantForm.controls.title.value;
  this.borrowerDto.person.firstName = this.coapplicantForm.controls.fName.value;
  this.borrowerDto.person.middleName = this.coapplicantForm.controls.mName.value;
  this.borrowerDto.person.lastName = this.coapplicantForm.controls.lName.value;
  this.borrowerDto.person.dateOfBirth = this.coapplicantForm.getRawValue().dob;
  this.borrowerDto.person.age = this.coapplicantForm.controls.age.value;
  this.borrowerDto.person.gender = this.coapplicantForm.getRawValue().gender;
  this.borrowerDto.personProfile.relationshipType = this.coapplicantForm.controls.relationshipToBusiness.value;
  this.borrowerDto.person.source = SOURCE_MANUAL;
  this.updateAddress();
  if(this.xDataResponse !== null && this.xDataResponse.status === "SUCCESS" ){
    this.borrowerDto.person.source = SOURCE_DIGITAL;
    this.borrowerDto.kyc = new KycProof();

    this.borrowerDto.kyc.linkToType = 'person';
    this.borrowerDto.kyc.contextType = 'application';
    this.borrowerDto.kyc.source = SOURCE_DIGITAL;
    this.borrowerDto.kyc.verificationMode = KYC_VERIFICATION_MODE_ELECTRONIC;
    this.borrowerDto.kyc.verifiedFlag = true;
    this.borrowerDto.kyc.contextUid = this.applicationUid;
  this.borrowerDto.personProfile.primaryPhone = this.xDataResponse.xdataPerson.phoneNumber;
  this.borrowerDto.kyc.kycProofDoc = this.xDataResponse.xdataEkyc.kycProofDoc;
  this.borrowerDto.kyc.documentNumber = this.xDataResponse.xdataEkyc.documentNumber;
  this.borrowerDto.kyc.issuingAuthority = this.xDataResponse.xdataEkyc.issuingAuthority;
  this.borrowerDto.kyc.proofValidity = this.xDataResponse.xdataEkyc.proofValidity;
  this.permanentAddress.addressLink.primaryPhone = this.xDataResponse.xdataPerson.phoneNumber;
  }
}

updateAddress(){
  this.borrowerDto.personAddressInformations = [];
  this.permanentAddress.addressLink.linkToType = 'person';
  this.permanentAddress.addressLink.contextUid = this.applicationUid;
  this.permanentAddress.addressLink.linkedAs = this.coapplicantForm.controls.addressType.value;
  this.permanentAddress.address.line1 = this.coapplicantForm.controls.firstLine.value;
  this.permanentAddress.address.line2 = this.coapplicantForm.controls.secondLine.value;
  this.permanentAddress.address.line3 = this.coapplicantForm.controls.thirdLine.value;
  this.permanentAddress.address.postalCode = this.coapplicantForm.controls.pinCode.value;
  this.permanentAddress.address.country = this.coapplicantForm.controls.country.value;
  this.permanentAddress.address.state = this.coapplicantForm.controls.state.value;
  this.permanentAddress.address.district =this.coapplicantForm.controls.district.value;
  this.permanentAddress.address.city = this.coapplicantForm.controls.city.value;
  this.permanentAddress.address.landmark = this.coapplicantForm.controls.landmark.value;
  this.borrowerDto.personAddressInformations.push(this.permanentAddress);
}

 //this function will apply validators to form group
applyValidatorsToCoApplicantAddingForm() {
 
  this.validationService.applyValidationRules(this.coapplicantForm, "CoApplicantAdding").then((controlValidators) => {
    
  }).catch(() => {

  })
  this.validationService.applyValidationRules(this.XDataForm, "ekycRequest").then((controlValidators) => {
    
  }).catch(() => {

  })
}
dataURItoBlob(dataURI) {
  // convert base64/URLEncoded data component to raw binary data held in a string
  var byteString;
  if (dataURI.split(',')[0].indexOf('base64') >= 0)
      byteString = atob(dataURI.split(',')[1]);
  else
      byteString = unescape(dataURI.split(',')[1]);

  // separate out the mime component
  var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

  // write the bytes of the string to a typed array
  var ia = new Uint8Array(byteString.length);
  for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
  }

  return new Blob([ia], {type:mimeString});
} 

blobToFile = (theBlob: Blob, fileName:string): File => {
  var b: any = theBlob;
  //A Blob() is almost a File() - it's just missing the two properties below which we will add
  b.lastModifiedDate = new Date();
  b.name = fileName;
  const myFile = new File([theBlob], "ekyc- "+this.xDataResponse.xdataPerson.fullName+" -image.jpeg", {
    type: theBlob.type,
  });
  //Cast to a File() type
  return <File>myFile ;
  }

  onSelectFile(event) {
    console.log(event.target);
  if (event.target.files && event.target.files[0]) {
    var reader = new FileReader();
    this.applicantImage=event.target.files
    reader.readAsDataURL(event.target.files[0]); // read file as data url

    reader.onload = (event) => {
       // called once readAsDataURL is completed
      this.url =reader.result;
    }
  }
}

  delete(){
    this.url = null;
  }
 
  ngOnDestroy(): void {
  }
}
