import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DateAdapter,MatSnackBar } from '@angular/material';
import { ValidationService } from '../../../application-validators/validation.service';
import { ApplicationService } from '@app/applications/application.service';
import { LoanApplication,ApplicationAccessSpecifier } from '@app/applications/applications.model';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { ReferenceCodeService } from '@app/admin/reference-code/reference-code.service';
import { NameValueDto } from '@ig-core/interfaces/name-value-dto';
import { FeePaymentDto } from './fee-payment.model';
import { FolderFilesComposite } from '../loan-folder-files/loan-folder-files.model';
import { ImagePreviewDialogService } from '@app/utils/image-preview-dialog/image-preview-dialog.service';
import { FileUploadService } from '@app/utils/file-upload/file-upload.service';
import * as fileSaver from 'file-saver';
import { ConfirmationDialogService } from '@app/utils/confirmation-dialog/confirmation-dialog.service';
@Component({
  selector: 'eng-fee-payment',
  templateUrl: 'fee-payment.component.html',
  styleUrls: ['../../application-details.styles.scss']
})
export class FeePaymentComponent implements OnInit {

  @Input()
  application: LoanApplication;
  @Input()
    menuCode:string;


  feePayments: FeePaymentDto[];
  feeTypeCategories: NameValueDto[];
  paymentType: NameValueDto[];
  feeForm: FormGroup;

  selectedFeePayment: any;
  isFormDisabled: boolean;
  showForm: boolean;
  allowAccess:Boolean;
  menuItemAllowAccess:boolean;
  feePaymentCopy: FeePaymentDto;

  applicationAccessSpecifiers: ApplicationAccessSpecifier[];
  menuItemAccessSpecifier: ApplicationAccessSpecifier;

  allFilesInFolder: FolderFilesComposite;

  constructor(private formBuilder: FormBuilder,
    private referenceCodeService: ReferenceCodeService,
    private applicationService: ApplicationService,
    private _snackbar: MatSnackBar,
    private dateAdapter: DateAdapter<Date>,
    private validationService: ValidationService,
    private imagePreviewDialogService: ImagePreviewDialogService,
    private fileUploadService: FileUploadService,
    private confirmationDialogService: ConfirmationDialogService) {
    this.feeForm = this.formBuilder.group({
      feeType:[ '', [ Validators.required]],
      referenceNo: [ '', [ Validators.required]],
      feeDate: '',
      feeAmount: [ '', [ Validators.required]],
      feePurpose: '',
      feeCharge: [ '', [ Validators.required]],
      bank: '',
      branch: '',
      voucherNumber:''
    });
      this.dateAdapter.setLocale('en-IN');
    this.allowAccess = this.applicationService.allowAccess;
  }

  ngOnInit(): void {
    this.isFormDisabled = true;
    this.getRefCodes();
    this.applyValidatorsToFee();
    this.getMenuItemAccess();


  }

  getRefCodes() {
    this.referenceCodeService.getRefCodesForClassifier('fees_and_charges_payment_method').subscribe((response: any) => {
      this.paymentType = response;
    });
    this.referenceCodeService.getRefCodesForClassifier('fees_and_charges_subcategory').subscribe((response: any) => {
      this.feeTypeCategories = response;
    });
  }

  fetchFeePayment() {
    this.showForm = false;
    this.applicationService.getFeesForApplication(
      this.application.uid).subscribe((response: any) => {
        if (response) {
          this.feePayments = response.body;
          this.showForm = (this.feePayments && this.feePayments.length > 0);
          this.buildFeePaymentForm();
        }
      });
  }

  addNewFee() {
    if (this.isFormDisabled) {
      this.changeSelectedFee(new FeePaymentDto());
      this.isFormDisabled = false;
    }
  }

  changeSelectedFee(feePayments?: FeePaymentDto) {
    this.showForm = true;
    this.buildFeePaymentForm(feePayments);
  }

  buildFeePaymentForm(fee?: FeePaymentDto) {
    if (this.showForm) {
      if (!fee) fee = this.feePayments[0];
      this.feeForm.patchValue({
        feeType: fee.paymentMethod,
        referenceNo: fee.referenceNumber,
        feeDate: fee.chequeDate === null || fee.chequeDate === undefined ? undefined : new Date(fee.chequeDate),
        feeAmount: fee.amount,
        feePurpose: fee.purpose,
        feeCharge: fee.subCategory,
        bank: fee.bankName,
        branch: fee.branchName,
        voucherNumber: fee.voucherReference
      });
      this.selectedFeePayment = fee;
      this.getSelectedFolderFiles(this.selectedFeePayment.chequeScanFolder)
    }
    // always we are disabling voucherNumber field, only after booking voucher this value will be populated with generated voucherReference number.
    this.feeForm.controls.voucherNumber.disable();
  }

 
  saveFee() {
    this.validationService.markFormGroupTouched(this.feeForm)
    if (!this.isFormDisabled && this.feeForm.valid) {
      let feeFormFields = this.feeForm.value;
      var feePaymentFormData = Object.assign({},this.selectedFeePayment);
      feePaymentFormData.referenceNumber = feeFormFields.referenceNo;
      feePaymentFormData.chequeDate = feeFormFields.feeDate === null || feeFormFields.feeDate === undefined ? undefined :feeFormFields.feeDate.getTime();
      feePaymentFormData.amount = feeFormFields.feeAmount;
      feePaymentFormData.purpose = feeFormFields.feePurpose;
      feePaymentFormData.paymentMethod = feeFormFields.feeType;
      feePaymentFormData.bankName = feeFormFields.bank;
      feePaymentFormData.branchName = feeFormFields.branch;
      feePaymentFormData.subCategory = feeFormFields.feeCharge;
      feePaymentFormData.linkToType = 'pdc';
      feePaymentFormData.linkToUid = feePaymentFormData.uid;
      this.applicationService.saveFeesAndCharge(this.application.uid,
        feePaymentFormData.uid , feePaymentFormData).toPromise().then((_success) => {
          this._snackbar.open("Fee Payment saved successfully", "Close", {
            duration: 2000,
          });
          this.isFormDisabled = true;
          this.fetchFeePayment();
        }, (failure) => {
          let errormesg =[]
          errormesg.push("Fee payment save failed")
          errormesg.push(failure)
          this.applicationService.displayErrorMessages(errormesg);
        }
        );
    }
  }

  enableDisableForm() {
    if(this.selectedFeePayment.voucherReference){
      this.isFormDisabled = true;
      this._snackbar.open("Voucher already booked. Edit not allowed.", "close" ,{
        duration: 6000
      })
      return;
    }
    this.isFormDisabled = false;
  }

  cancelForm() {
    if (this.feePayments && this.feePayments.length > 0) {
      this.buildFeePaymentForm(this.feePayments[0]);
    } else {
      this.showForm = false;
    }
    this.isFormDisabled = true;
  }

  //this function will apply validators to form group
  applyValidatorsToFee() {
    this.validationService.applyValidationRules(this.feeForm, "FeePayment").then((controlValidators) => {
      this.fetchFeePayment();
    }).catch(() => {
    })
  }


  //this function will get either this menu item  is editable or readonly 
  getMenuItemAccess(){
    this.applicationService.getApplicationAccessModifiers(this.application.uid)
        .subscribe((response) => {
          this.applicationAccessSpecifiers = response.body;
          this.menuItemAccessSpecifier = this.applicationAccessSpecifiers
            .find(accessSpecifier => accessSpecifier.category === this.menuCode);
          if(this.menuItemAccessSpecifier){
          this.menuItemAllowAccess = this.menuItemAccessSpecifier.allowAccess;
            }
      });
    }

 //this function is called on click of checkDate input
  addEvent(type: string, event: MatDatepickerInputEvent<Date>) {
    if (!this.isFormDisabled) {
      if (event.value == null) {
        this.feeForm.controls.feeDate.patchValue(undefined);
      }
    }
  }

  getSelectedFolderFiles(folderUid) {
    if (folderUid) {
      this.applicationService.getAllFilesInApplicationFolder(this.application.uid, folderUid).subscribe((response: any) => {
        if (response) {
          this.allFilesInFolder = response.body;
          this.allFilesInFolder.fileInfo.forEach(element => {
            if (element.fileSize) {
              element["convertedFileSize"] = this.applicationService.bytesToSize(element.fileSize)
            }
          });
        }
      },error =>{
        this.allFilesInFolder={
          folderInfo:{},
          fileInfo:[]
        }
      });
    }
  }

    //this function open up the image viewer dailog box
    openImagePreviewDialog() {
      this.imagePreviewDialogService.open({ "images": this.allFilesInFolder })
    }

  //this function open up the image upload dailog box
  //if file upload should allow single and multiple file upload, we need to pass "uploadType":"multiple"
  openImageFileUploadDialog() {
    if (this.selectedFeePayment.chequeScanFolder) {
      this.fileUploadService.open({ "uploadType": "multiple", "folderUid": this.selectedFeePayment.chequeScanFolder, "applicationUid": this.application.uid, "files": this.allFilesInFolder.fileInfo })
      this.fileUploadService.confirmed().subscribe(data => {
        this.getSelectedFolderFiles(data.folderUid)
      })

    } else {
      this._snackbar.open("Please add a folder to upload files", "Close", {
        duration: 5000,
      });
    }
  }

    //this function will call onclick of download icon in file's section
    downloadFile(file) {
      this.applicationService.downloadFileFromApplicationFolder(this.application.uid, file.folderUid, file.fileId).subscribe(image => {
        const blob = new Blob([image.data], { type: file.type });
        fileSaver.saveAs(blob, file.fileName);
        this._snackbar.open("File has been downloaded successfully", "Close", {
          duration: 5000,
        });
      })
    }

    openfileDeleteConfirmation(selectedFile){
      this.confirmationDialogService.open({ "btnCancelText":"Cancel","btnConfirmText":"Delete","message":"Are you sure you want to delete this File?"})
      this.confirmationDialogService.confirmed().subscribe(data=>{
        if(data){
        this.deleteFile(selectedFile)
        }
      }) 
    }

    //this function will be called on click of delete icon on file list
    deleteFile(selectedFile){
      this.applicationService.fileDelete(this.application.uid, selectedFile.uid).subscribe((response) => {
        if(response.body.status == "success") {
        this._snackbar.open("File deleted successfully", "Close", {
          duration: 2000,
        });
        this.getSelectedFolderFiles(this.selectedFeePayment.chequeScanFolder);
      } else {
        let errormesg =[]
        errormesg.push("File deleted failed")
        errormesg.push(response.body.message)
        this.applicationService.displayErrorMessages(errormesg);
      }}, (failure) => {
        let errormesg =[]
        errormesg.push("File deleted failed")
        errormesg.push(failure)
        this.applicationService.displayErrorMessages(errormesg);
      });
    }

}