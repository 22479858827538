import { Component, OnInit, Input } from '@angular/core';
import { LoanApplication,ApplicationAccessSpecifier} from '@app/applications/applications.model';
import { ApplicationService } from '../../../application.service';
import { FormControl, FormGroup, FormArray, FormBuilder, AbstractControl } from '@angular/forms';
import { ReferenceCodeService } from '@app/admin/reference-code/reference-code.service';
import { Decision } from '../decision.model';
import { MatSnackBar } from '@angular/material';
import { CollateralDetail } from '../../collateral/collateralSummary/collateral-summary.model';
@Component({
  selector: 'eng-decision',
  templateUrl: './decision.template.html',
  styleUrls: ['../../application-details.styles.scss', './decision.styles.css']
})
export class DecisionComponent implements OnInit {
  @Input()
  application: LoanApplication;
  @Input() menuItem:string
  @Input()
    menuCode:string;
  decisiondeviationList: object;
  decisionListItems: Decision;
  deviationItems: object;
  deviationTypes: object;
  decisionForm: FormGroup

  allowAccess:Boolean; 
  cfaFinalized:Boolean;
  menuItemAllowAccess:boolean;

  trailLabel:string='HIDE TRAIL';
  applicationState: string;
  collateralMessage:string ="";

  hideOrShowTrail:boolean=false;
  isCollateralFinalized: boolean;

  applicationAccessSpecifiers: ApplicationAccessSpecifier[];
  menuItemAccessSpecifier: ApplicationAccessSpecifier;
  collateralDetails : CollateralDetail;
  decisionItemGrant: any;
  // this variable is used to show or hide approval cards for approval item and approval trail
  // in approvalTrail we need to show approvalCard irrespective of decitionItemGrant, hence we are using this variable
  showApprovedTrailFlag: boolean;

  constructor(private applicationService: ApplicationService,
    private formBuilder: FormBuilder,
    private _snackbar: MatSnackBar,
    private referenceCodeService: ReferenceCodeService) { 
      this.allowAccess = this.applicationService.allowAccess; 
    }

  ngOnInit() {
    this.getCfaStatus()
    this.getReferenceCodes()
    this.getDesisionItems()
    this.getMenuItemAccess();
    this.getDecisionItemGrants();
    if(this.menuItem !== 'approvalTrail'){
      this.getApplicationState();
    }
 
  }

  getDesisionItems() {
    this.applicationService.getDecisions(this.application.uid).subscribe(response => {
      console.log('response', response)
      this.decisionListItems = response.body
     this.buildDecisionForm()
    })
  }

  buildDecisionForm(){
    this.decisionForm = this.formBuilder.group({
      loanAmountRemarks: this.decisionListItems.application.recommendRemarks,
      loanAmountrecommendFlag: this.decisionListItems.application.recommendFlag,
      loanAmountResetFlag: this.decisionListItems.application.resetFlag,
      deviationRemarks: new FormArray(this.decisionListItems.deviations.map(data => new FormControl(data.deviation.recommendRemarks))),
      deviationrecommendFlag: new FormArray(this.decisionListItems.deviations.map(data => new FormControl(data.deviation.recommendFlag))),
      deviationResetFlag: new FormArray(this.decisionListItems.deviations.map(data => new FormControl(data.resetFlag))),
    })
    this.decisionForm.disable()
  }
  enableDisableForm() {
    this.decisionForm.enable()
  }
  saveDecision() {
    this.decisionListItems.application.approvaltrail = []
    this.decisionListItems.application.recommendFlag = this.decisionForm.controls.loanAmountrecommendFlag.value
    this.decisionListItems.application.recommendRemarks = this.decisionForm.controls.loanAmountRemarks.value
    this.decisionListItems.application.resetFlag = this.decisionForm.controls.loanAmountResetFlag.value
    if (this.decisionForm.get('deviationrecommendFlag')['controls'].length !== 0 && this.decisionForm.get('deviationRemarks')['controls'].length !== 0) {
      this.decisionListItems.deviations.forEach((element, index) => {
        element.approvaltrail = []
        element.resetFlag=this.decisionForm.get('deviationResetFlag')['controls'][index].value
        element.deviation.recommendFlag = this.decisionForm.get('deviationrecommendFlag')['controls'][index].value
        element.deviation.recommendRemarks = this.decisionForm.get('deviationRemarks')['controls'][index].value
      });
    }
    this.applicationService.saveDecisionItems(this.application.uid,
      this.decisionListItems).subscribe((response: any) => {
        this.decisionListItems = response
        this.decisionForm.disable()
        this._snackbar.open("Decisions updated successfully", "Close", {
          duration: 2000,
        });
      });
  }

  getReferenceCodes() {
    this.referenceCodeService.getShortRefCodes('deviation_type').subscribe((response: any) => {
      this.deviationTypes = response.deviation_type;
    });
    this.referenceCodeService.getShortRefCodes('deviation_item').subscribe((response: any) => {
      this.deviationItems = response.deviation_item;
    });
  }
  cancelForm() {
    this.decisionForm.disable()
    this.buildDecisionForm()
    
  }

  //this function will call a api to get CFA Finalized or not status
  getCfaStatus(){
    this.applicationService.getCfaStatus(this.application.uid).subscribe(response =>{
      this.cfaFinalized=response.body.cfaFinalized
      if(this.cfaFinalized){
        // this._snackbar.open("CFA is finalized. Financial data cannot be modified", "Close" ,{
        //   duration:5000,
        // });
      }
    })
  }

  hideOrShowTrailfunction(){
    this.hideOrShowTrail=!this.hideOrShowTrail
    if(this.hideOrShowTrail){
      this.trailLabel='SHOW TRAIL'
    }else{
      this.trailLabel='HIDE TRAIL'
    }
   
  }
  //this function will get either this menu item  is editable or readonly 
  getMenuItemAccess(){
    this.applicationService.getApplicationAccessModifiers(this.application.uid)
        .subscribe((response) => {
          this.applicationAccessSpecifiers = response.body;
          this.menuItemAccessSpecifier = this.applicationAccessSpecifiers
            .find(accessSpecifier => accessSpecifier.category === this.menuCode);
          if(this.menuItemAccessSpecifier){
          this.menuItemAllowAccess = this.menuItemAccessSpecifier.allowAccess;
            }
      });
    }

    getApplicationState() {
      this.applicationService.getApplicationState(this.application.uid)
          .subscribe( response => {
        this.applicationState = response.body.applicationState;
        if(this.applicationState === "PROVISIONAL_APPROVED"){
          this.getCollateralStatus()
        }
      });
    }

    getCollateralStatus() {
      this.applicationService.getCollateralStatus(this.application.uid)
        .subscribe(response => {
          this.isCollateralFinalized = response.body.collateralFinalized;
          if(this.isCollateralFinalized){
            this.fetchCollateranSummary()
          }else{
            this.collateralMessage="Collateral not finalized"
          }
      });
    }

    fetchCollateranSummary() {
      this.applicationService.getCollateralSummary(this.application.uid)
          .subscribe((response: any) => {
        if (response) {
          this.collateralDetails = response;
          if(this.collateralDetails ){
            this.collateralMessage="Proposed LTV% is "+ this.collateralDetails.propToValLtvPct+" and Applicable LTV% "+this.collateralDetails.ltvPctApplicable
          }
        }
      });
    }

    // based on the decisionItemTag name we are showing approval card, it decisionItemTag is null we are hiding the card itself
    // now approval cards will show only in the stages which will have decitionItemTags like(AMT-PROV-APPR,DVTN-APPR)
    getDecisionItemGrants() {
      this.showApprovedTrailFlag = false;
      this.applicationService.getApplicationDecisionItemGrants(this.application.uid)
        .subscribe((response) => {
          this.decisionItemGrant = response.body.decisionItems;

          // if decisionItemGrant includes amountApproval or ltvFinalApproval then we need to show the amount Approval card,
          // since we are using same component for approvalTrail as well, we are checking if menuItem is approvalTrail then also we need to show approvalCard
          // so we are using this showApprovalTrailFlag. If showApprovalTrailFlag is true then we are showing amountApproval card and showing different title.
          if(this.decisionItemGrant && (this.decisionItemGrant.includes('AMT-PROV-APPR') || this.decisionItemGrant.includes('LTV-FINAL-APPR'))){
              this.showApprovedTrailFlag = true;
          }else if(this.menuItem === 'approvalTrail'){
            this.showApprovedTrailFlag = true;
          }
        })
    }

}
