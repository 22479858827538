import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NameValueDto } from '@ig-core/interfaces/name-value-dto';
import { ReferenceCodeService } from '@app/admin/reference-code/reference-code.service';
import { ApplicationService } from '@app/applications/application.service';
import { Applicant, Collateral, ColletralDetails } from './collateral-details.model';
import { LoanApplication, ApplicationAccessSpecifier } from '@app/applications/applications.model';
import { Address } from '../../applicant/applicantAddress/applicant-address.model';
import { ReferenceCode } from '@app/admin/reference-code/reference-code.model';
import { ValidationService } from '../../../application-validators/validation.service'
import { MatSnackBar } from '@angular/material';
import { ConfirmationDialogService } from '@app/utils/confirmation-dialog/confirmation-dialog.service';
import { FileUploadService } from '@app/utils/file-upload/file-upload.service';
import { File, Folder, FolderFilesComposite } from '@app/applications/application-details/loanInformation/loan-folder-files/loan-folder-files.model';
import * as fileSaver from 'file-saver';
import { ImagePreviewDialogService } from '@app/utils/image-preview-dialog/image-preview-dialog.service';
@Component({
  selector: 'eng-collateral-details',
  templateUrl: 'collateral-details.template.html',
  styleUrls: ['../../application-details.styles.scss','./collateral-details.scss']
})
export class CollateralDetailsComponent implements OnInit {

  @Input()
  application: LoanApplication;
  @Input()
  menuCode: string;

  colletralDetails: ColletralDetails[];
  applicants: Applicant[];
  collateral: Collateral;
  address: Address;
  collateralApplicantsList: NameValueDto[] = [];
  selectedCollateral: ColletralDetails;
  collatrelDetailsForm: FormGroup;
  applicantsForm: FormGroup;
  collateralTypes: ReferenceCode[] = [];
  collateralUses: NameValueDto[];
  maxLtv: ReferenceCode[];

  showForm: boolean;
  isFormDisabled: boolean;
  someFormDisable: boolean;
  allowAccess: boolean;
  menuItemAllowAccess: boolean;
  isCollateralFinalized: boolean;

  allFilesInFolder: FolderFilesComposite;

  applicationAccessSpecifiers: ApplicationAccessSpecifier[];
  menuItemAccessSpecifier: ApplicationAccessSpecifier;
  constructor(private formBuilder: FormBuilder,
    private referenceCodeService: ReferenceCodeService,
    private applicationService: ApplicationService,
    private _snackbar: MatSnackBar,
    private fileUploadService: FileUploadService,
    private imagePreviewDialogService: ImagePreviewDialogService,
    private confirmationDialogService: ConfirmationDialogService,
    private validationService: ValidationService) {
    this.collatrelDetailsForm = this.formBuilder.group({
      collateralType: '',
      collateralUse: '',
      firstLine: '',
      secondLine: '',
      thirdLine: '',
      ownedBy: '',
      landArea: '',
      buildingArea: '',
      distanceFromBranch: '',
      fairMarketValue: '',
      maxLtv: '',
      ltvMarketValue: '',
      pdDetailsGroup: this.formBuilder.group({
        visitedBy: '',
        technicalValuationValue: '',
        loanProposed: '',
        proposedLoanValue: '',
      })

    });
    this.allowAccess = this.applicationService.allowAccess;
  }

  ngOnInit() {
    this.isFormDisabled = true;
    this.refreshApplicationData();
    this.fetchApplicants();
    
    this.getMenuItemAccess();
    this.getCollateralStatus();
    
  }

  getRefCodes() {
    //it is possible that we may restrict that -prop must be present 
    let collateralSet = this.application.collateralSet
    this.referenceCodeService.getRefCodesForClassifier(collateralSet).subscribe((response: any) => {
      this.collateralTypes = response;
      this.applyValidatorsToCollateralDetails();
    });

    this.referenceCodeService.getShortRefCodes('collateral_usedfor').subscribe((response: any) => {
      this.collateralUses = response.collateral_usedfor;
    });
  }

  fetchCollateralDetails() {
    this.showForm = false;
    this.applicationService.getCollateralDetails(this.application.uid).subscribe((response: any) => {
      if (response) {
        this.colletralDetails = response;
        
        this.colletralDetails.forEach((element, index) => {
          this.colletralDetails[index]["deleteBtnDisabled"] = true
          let CheckType=this.collateralTypes.filter(object=>{return object.code ==element.collateral.collateralType })
          if(CheckType.length ==0 && this.collateralTypes.length !=0 ){
            this.colletralDetails[index]["showInRed"] = true
          }
        });
        this.showForm = (this.colletralDetails && this.colletralDetails.length > 0);
        this.buildCollateralDetailsForm();
        this.proposedLoanCalculation();
      }
    })
  }

  fetchApplicants() {
    this.showForm = false;
    this.applicationService.getApplicants(this.application.uid).subscribe((response: any) => {
      if (response) {
        this.applicants = response;
        this.collateralApplicants();
      }
    })
  }

  collateralApplicants() {
    this.applicants.forEach((applicantsObject) => {
      let applicant = { code: applicantsObject.uid, name: applicantsObject.name + '  ' + '(' + applicantsObject.play + ')' }
      this.collateralApplicantsList.push(<NameValueDto>applicant);
    })
  }

  proposedLoanCalculation() {
    let proposedLoanToValue;

    if (this.collatrelDetailsForm.get('pdDetailsGroup')['controls']['loanProposed'].value &&
      this.collatrelDetailsForm.get('pdDetailsGroup')['controls']['technicalValuationValue'].value !== null) {
      let loanProposedValue = this.collatrelDetailsForm.get('pdDetailsGroup')['controls']['loanProposed'].value
      let technicalValuation = this.collatrelDetailsForm.get('pdDetailsGroup')['controls']['technicalValuationValue'].value
      proposedLoanToValue = ((loanProposedValue / technicalValuation * 100).toFixed(2));
      if (proposedLoanToValue != "Infinity") {
        this.collatrelDetailsForm.controls.pdDetailsGroup.patchValue({ proposedLoanValue: proposedLoanToValue })
      } else {
        this.collatrelDetailsForm.controls.pdDetailsGroup.patchValue({ proposedLoanValue: 0 })
      }
    }else{
      this.collatrelDetailsForm.controls.pdDetailsGroup.patchValue({ proposedLoanValue: '' })
    }
  }


  updateMaxLTV() {
    if (this.collatrelDetailsForm.controls['collateralType'].value) {
      let selectedCollateral = this.getCollateralTypeByCode(this.collatrelDetailsForm.controls['collateralType'].value)
      if (selectedCollateral) {
        this.collatrelDetailsForm.patchValue({ maxLtv: selectedCollateral.field1 });
        this.basedOnProductTypeDisableFields();
      }
    } else {
      this.collatrelDetailsForm.patchValue({ maxLtv: '' });
    }
  }

  addNewCollateral() {
    //if (this.isFormDisabled) {
      let collateralDetails = new ColletralDetails();
      collateralDetails.address = new Address();
      collateralDetails.collateral = new Collateral();
      this.collatrelDetailsForm.enable()
      this.collatrelDetailsForm.controls.maxLtv.disable();
      this.collatrelDetailsForm.controls.ltvMarketValue.disable();
      this.collatrelDetailsForm.controls.pdDetailsGroup.disable()
      this.collatrelDetailsForm.get("pdDetailsGroup")["controls"].visitedBy.enable()
      this.isFormDisabled = false;
      this.changeSelectedCollateral(collateralDetails, undefined, "new");
    //s}

  }

  changeSelectedCollateral(collateraldetails?: ColletralDetails, index?: number, from?: string) {
    this.showForm = true;
    this.colletralDetails.forEach((element, i) => {
      this.colletralDetails[i].deleteBtnDisabled = true
    });
 

    this.buildCollateralDetailsForm(collateraldetails)
    this.proposedLoanCalculation()
    this.basedOnProductTypeDisableFields(from);
    
    if (from != "new") {
      this.colletralDetails[index].deleteBtnDisabled = false
      this.collatrelDetailsForm.disable();
    }
  }

  buildCollateralDetailsForm(collaterals?: ColletralDetails) {
    if (this.showForm) {

      if (!collaterals) {
        collaterals = this.colletralDetails[0];
        this.colletralDetails[0].deleteBtnDisabled = false
      }
      this.collatrelDetailsForm.patchValue({
        collateralType: collaterals.collateral.collateralType,
        collateralUse: collaterals.collateral.collateralUse,
        firstLine: collaterals.address === null ? '' : collaterals.address.line1,
        secondLine: collaterals.address === null ? '' : collaterals.address.line2,
        thirdLine: collaterals.address === null ? '' : collaterals.address.line3,
        ownedBy: collaterals.collateral.ownedByUid,
        landArea: collaterals.collateral.landArea,
        buildingArea: collaterals.collateral.buildingArea,
        distanceFromBranch: collaterals.collateral.distance,
        fairMarketValue: collaterals.collateral.fairMarketValue,
        ltvMarketValue: collaterals.collateral.ltvApplied
      });
      this.collatrelDetailsForm.controls.pdDetailsGroup.patchValue({
        loanProposed: this.application.amountProp,
        technicalValuationValue: collaterals.collateral.evalMarketValue,
        visitedBy: collaterals.collateral.visitedBy
      })
      this.selectedCollateral = collaterals;
      this.updateMaxLTV();
      if (collaterals.collateral.collateralType != undefined) {
        this.collatrelDetailsForm.disable();
      }
      this.getSelectedFolderFiles(this.selectedCollateral.collateral.fileFolderUid);
    }
  }


  saveCollateralDetails() {
    this.validationService.markFormGroupTouched(this.collatrelDetailsForm)
    if (!this.isFormDisabled && this.collatrelDetailsForm.valid) {
      if (!this.selectedCollateral.collateral.uid) {
        this.selectedCollateral.collateral.contextUid = this.application.uid;
        this.selectedCollateral.collateral.contextType = 'application';
      }
      let collatrelDetailsFormFields = this.collatrelDetailsForm.controls;
      this.selectedCollateral.collateral.collateralType = collatrelDetailsFormFields.collateralType.value;
      this.selectedCollateral.collateral.collateralUse = collatrelDetailsFormFields.collateralUse.value;
      this.selectedCollateral.address = new Address()
      this.selectedCollateral.address.line1 = collatrelDetailsFormFields.firstLine.value;
      this.selectedCollateral.address.line2 = collatrelDetailsFormFields.secondLine.value;
      this.selectedCollateral.address.line3 = collatrelDetailsFormFields.thirdLine.value;
      this.selectedCollateral.collateral.ownedByUid = collatrelDetailsFormFields.ownedBy.value;
      this.selectedCollateral.collateral.landArea = collatrelDetailsFormFields.landArea.value;
      this.selectedCollateral.collateral.buildingArea = collatrelDetailsFormFields.buildingArea.value;
      this.selectedCollateral.collateral.distance = collatrelDetailsFormFields.distanceFromBranch.value;
      this.selectedCollateral.collateral.fairMarketValue = collatrelDetailsFormFields.fairMarketValue.value;
      this.selectedCollateral.collateral.maxLtv = collatrelDetailsFormFields.maxLtv.value;
      this.selectedCollateral.collateral.ltvApplied = collatrelDetailsFormFields.ltvMarketValue.value;
      this.selectedCollateral.collateral.visitedBy = this.collatrelDetailsForm.get("pdDetailsGroup")["controls"].visitedBy.value;

      this.applicationService.saveCollateralDetails(this.application.uid, this.selectedCollateral.collateral.uid, this.selectedCollateral).toPromise().then(
        (_success) => {
          this._snackbar.open("Collateral updated successfully", "Close", {
            duration: 2000,
          });
          this.isFormDisabled = true
          this.fetchCollateralDetails();
        }, (failure) => {
          let errormesg = []
          errormesg.push("Collateral update failed")
          errormesg.push(failure)
          this.applicationService.displayErrorMessages(errormesg);
        }
      );
    }
  }

  basedOnProductTypeDisableFields(from?: string) {
    if (from != "new") {
      this.collatrelDetailsForm.enable()
      this.collatrelDetailsForm.controls.maxLtv.disable();
      this.collatrelDetailsForm.controls.ltvMarketValue.disable();
      this.collatrelDetailsForm.controls.pdDetailsGroup.disable()
      this.collatrelDetailsForm.get("pdDetailsGroup")["controls"].visitedBy.enable()
      let collateralTypeRefcodeDetails = this.getCollateralTypeByCode(this.collatrelDetailsForm.controls['collateralType'].value)
      if (collateralTypeRefcodeDetails) {
        if (collateralTypeRefcodeDetails.field2 === "FMVONLY") {
          this.collatrelDetailsForm.controls.collateralUse.disable();
          this.collatrelDetailsForm.controls.firstLine.disable();
          this.collatrelDetailsForm.controls.secondLine.disable();
          this.collatrelDetailsForm.controls.thirdLine.disable();
          this.collatrelDetailsForm.controls.ownedBy.disable();
          this.collatrelDetailsForm.controls.landArea.disable();
          this.collatrelDetailsForm.controls.buildingArea.disable();
          this.collatrelDetailsForm.controls.distanceFromBranch.disable();
        }
      }
    } else {
      this.collatrelDetailsForm.disable();
      this.collatrelDetailsForm.controls.collateralType.enable();
    }
  }

  enableDisableForm() {
    this.colletralDetails.forEach((element, i) => {
      this.colletralDetails[i].deleteBtnDisabled = true
    });
    this.basedOnProductTypeDisableFields();
    this.isFormDisabled = false;
    if(this.isCollateralFinalized){
    this.collatrelDetailsForm.controls.collateralType.disable()
    }
  }

  cancelForm() {
    this.colletralDetails.forEach((element, i) => {
      this.colletralDetails[i].deleteBtnDisabled = true
    });
    if (this.colletralDetails && this.colletralDetails.length > 0) {
      this.colletralDetails[0].deleteBtnDisabled = false
      this.buildCollateralDetailsForm(this.colletralDetails[0]);
    } else {
      this.showForm = false;
    }
    this.isFormDisabled = true;
    this.collatrelDetailsForm.disable();
  }
  //this function will apply validators to form group
  applyValidatorsToCollateralDetails() {
    this.validationService.applyValidationRules(this.collatrelDetailsForm, "CollateralDetails").then((controlValidators) => {
      this.fetchCollateralDetails();
      this.collatrelDetailsForm.disable();
      //this.basedOnProductTypeDisableFields();
    }).catch(() => {
    })
  }

  //this function will get either this menu item  is editable or readonly 
  getMenuItemAccess() {
    this.applicationService.getApplicationAccessModifiers(this.application.uid)
      .subscribe((response) => {
        this.applicationAccessSpecifiers = response.body;
        this.menuItemAccessSpecifier = this.applicationAccessSpecifiers
          .find(accessSpecifier => accessSpecifier.category === this.menuCode);
        if (this.menuItemAccessSpecifier) {
          this.menuItemAllowAccess = this.menuItemAccessSpecifier.allowAccess;
        }
      });
  }

  getCollateralTypeByCode(code) {
    if (this.collateralTypes.length != 0) {
      return this.collateralTypes.filter(collateralType => collateralType.code === code)[0];
    }
  }

  getCollateralStatus() {
    this.applicationService.getCollateralStatus(this.application.uid)
      .subscribe(response => {
        this.isCollateralFinalized = response.body.collateralFinalized;
      });
  }

  //this function will call to get basic application data 
  // to refresh "this.application" values after particular action eg: after decision save like proceed in sidenav
  refreshApplicationData() {
    this.applicationService.getBasicApplicationInfo(
      this.application.uid, 'ApplicationOnly').subscribe((response) => {
        this.application = response.body.applicationDTO;
        this.getRefCodes();
      });
  }

  //this function will be called on click of delete icon on collatral list
  deleteCollateral(selectedCollateral) {
    this.applicationService.collateralDelete(this.application.uid, selectedCollateral.collateral.uid).subscribe((response) => {
      if (response.body.status == "success") {
        this._snackbar.open("Collateral deleted successfully", "Close", {
          duration: 2000,
        });
        this.fetchCollateralDetails();
      } else {
        let errormesg = []
        errormesg.push("Collateral deleted failed")
        errormesg.push(response.body.message)
        this.applicationService.displayErrorMessages(errormesg);
      }
    }, (failure) => {
      let errormesg = []
      errormesg.push("Collateral deleted failed")
      errormesg.push(failure)
      this.applicationService.displayErrorMessages(errormesg);
    });
  }

  openDeleteConfirmation() {
    this.confirmationDialogService.open({ "btnCancelText": "Cancel", "btnConfirmText": "Delete", "message": "Are you sure you want to delete this collateral?" })
    this.confirmationDialogService.confirmed().subscribe(data => {
      if (data) {
        this.deleteCollateral(this.selectedCollateral)
      }
    })
  }

      
//this function open up the image upload dailog box
  //if file upload should allow single and multiple file upload, we need to pass "uploadType":"multiple"
  openImageFileUploadDialog() {
    if(this.selectedCollateral.collateral != null){
       this.fileUploadService.open({ "uploadType": "multiple", "folderUid": this.selectedCollateral.collateral.fileFolderUid, "applicationUid": this.application.uid, "files":this.allFilesInFolder.fileInfo })
      this.fileUploadService.confirmed().subscribe(data=>{
        this.getSelectedFolderFiles(data.folderUid)
      })
    
    }else{
      this._snackbar.open("Please add a folder to upload files", "Close", {
        duration: 5000,
      });
    }
  }


  getSelectedFolderFiles(folderUid) {
    this.applicationService.getAllFilesInApplicationFolder(this.application.uid,folderUid).subscribe((response: any) => {
      if (response) {
        this.allFilesInFolder = response.body;
        this.allFilesInFolder.fileInfo.forEach(element => {
          if (element.fileSize) {
            element["convertedFileSize"] = this.applicationService.bytesToSize(element.fileSize)
          }
        });
      }
    });
  }

  //this function will call onclick of download icon in file's section
  downloadFile(file) {
    this.applicationService.downloadFileFromApplicationFolder(this.application.uid, file.folderUid, file.fileId).subscribe(image => {
      const blob = new Blob([image.data], { type: file.type });
      fileSaver.saveAs(blob, file.fileName);
      this._snackbar.open("File has been downloaded successfully", "Close", {
        duration: 5000,
      });
    })
  }

   //this function will trigger onclicking of image,will open up a mat-dailog
   openContentElement() {
    this.imagePreviewDialogService.open({ "images": this.allFilesInFolder })
  }

  
  openfileDeleteConfirmation(selectedFile){
    this.confirmationDialogService.open({ "btnCancelText":"Cancel","btnConfirmText":"Delete","message":"Are you sure you want to delete this File?"})
    this.confirmationDialogService.confirmed().subscribe(data=>{
      if(data){
      this.deleteFile(selectedFile)
      }
    })
  
}

//this function will be called on click of delete icon on file list
deleteFile(selectedFile){
  this.applicationService.fileDelete(this.application.uid, selectedFile.uid).subscribe((response) => {
    if(response.body.status == "success") {
    this._snackbar.open("File deleted successfully", "Close", {
      duration: 2000,
    });
    this.getSelectedFolderFiles(this.selectedCollateral.collateral.fileFolderUid);
  } else {
    let errormesg =[]
    errormesg.push("File deleted failed")
    errormesg.push(response.body.message)
    this.applicationService.displayErrorMessages(errormesg);
  }}, (failure) => {
    let errormesg =[]
    errormesg.push("File deleted failed")
    errormesg.push(failure)
    this.applicationService.displayErrorMessages(errormesg);
  });
}

}