import { HttpClientModule } from '@angular/common/http';
import { NgModule,CUSTOM_ELEMENTS_SCHEMA,NO_ERRORS_SCHEMA } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { MaterialLibModule } from '@app/material-lib.module';
import { ApplicationsRoutingModule } from './applications-routing.module';
import { ApplicationsComponent } from './applications.component';
import { ApplicantAddressComponent } from './application-details/applicant/applicantAddress/applicant-address.component';
import { ApplicantBankAccountsComponent } from './application-details/applicant/applicantBankAccount/applicant-bank-account.component';
import { ApplicantDocumentComponent } from './application-details/applicant/applicantDocuments/applicant-document.component';
import { ApplicantInformationComponent } from './application-details/applicant/applicantInformation/applicant-information.component';
import { ApplicantKYCProofsComponent } from './application-details/applicant/applicantKycProofs/applicant-kyc-proof.component';
import { ApplicantReferenceComponent } from './application-details/applicant/applicantReference/applicant-reference.component';
import { ApplicationDetailsComponent } from './application-details/application-details.component';
import { BusinessAddressesComponent } from './application-details/business/businessAddress/business-addresses.component';
import { BusinessBankAccountsComponent } from './application-details/business/businessBankAccount/business-bank-accounts.component';
import { BusinessDocumentComponent } from './application-details/business/businessDocuments/business-document.component';
import { BusinessInformationComponent } from './application-details/business/businessInformation/business-information.component';
import { BusinessReferenceComponent } from './application-details/business/businessReference/business-reference.component';
import { KYCProofsComponent} from './application-details/business/kycProofs/kyc-proofs.component';
import { CollateralDetailsComponent } from './application-details/collateral/collateralDetails/collateral-details.components';
import { ColletralSummaryComponent } from './application-details/collateral/collateralSummary/collateral-summary.component';
import { CFAComponent } from './application-details/financials/cfa/cfa.component';
import { GSTReturnsComponent } from './application-details/financials/gstReturns/gst-returns.component';
import { ITReturnsComponent } from './application-details/financials/itReturns/it-returns.component';
import { LiabilityComponent } from './application-details/financials/liability/liability.component';
import { PurchaseBillsComponent } from './application-details/financials/purchaseBills/purchase-bills.component';
import { SalesBillsComponent } from './application-details/financials/salesBills/sales-bills.component';
import { LoanProposedComponent } from './application-details/loanInformation/loanProposed/loan-proposed.component';
import { LoanRequestedComponent } from './application-details/loanInformation/loanRequested/loan-requested.component';
import { AllPipesModule } from '../utils/pipes.module';
import { BusinessExpensesComponent } from './application-details/financials/business-expenses/business-expenses.component';
import { FamilyIncomeComponent } from './application-details/financials/family-income/family-income.component';
import { FamilyExpensesComponent } from './application-details/financials/familyExpenses/family-expenses.component';
import { ConditionsComponent } from './application-details/cam/conditions/conditions.component';
import { DeviationComponent } from './application-details/cam/deviation/deviation.component';
import { DecisionComponent } from './application-details/decisions/decision/decision.component';
import { ColletralVerificationComponent } from './application-details/collateral/collateralVerification/collateral-verification.component';
import { NgxImageZoomModule } from 'ngx-image-zoom';
import { PreConditionComponent } from './application-details/applicationDocument/preCondition/pre-condition.component';
import { BankStatementsComponent } from './application-details/financials/bank-statements/bank-statements.component';
import { PostDateChequeComponent } from './application-details/applicationDocument/postDateCheque/post-date-cheque.component';
import { CheckListComponent } from './application-details/applicationDocument/check-list/check-list.component';
import { QuestionAnswerSystemComponent } from './application-details/cam/question-answer-system/question-answer-system.component';
import { ChangeRequestComponent } from './application-details/applicationDocument/change-request/change-request.component';
import { ApplicationTrailComponent } from './application-details/decisions/application-trail/application-trail.component';
import { ImagePreviewDialogModule } from '@app/utils/image-preview-dialog/image-preview-dialog.module';
import { RepaymentAccountComponent } from './application-details/loanInformation/repayment-account/repayment-account.component';
import { FileUploadModule } from '@app/utils/file-upload/file-upload.module';
import { ApprovalPreConditionComponent } from './application-details/applicationDocument/approval-pre-condition/approval-pre-condition.component';
import { ChangeApprovalComponent } from './application-details/decisions/change-approval/change-approval.component';
import { LoanFolderFilesComponent } from './application-details/loanInformation/loan-folder-files/loan-folder-files.component';
import { BookLoanComponent } from './application-details/applicationDocument/book-loan/book-loan.component';
import { BureauReportsComponent } from './application-details/applicant/bureau-reports/bureau-reports.component';
import { ReviseApplicationComponent } from './application-details/loanInformation/revise-application/revise-application.component';
import { ConfirmationDialogModule } from '@app/utils/confirmation-dialog/confirmation-dialog.module';
import { DedupCheckComponent } from './application-details/dedup-check/dedup-check.component';
import {GoToPageModule} from'@app/utils/go-to-page/go-to-page.module';
import { ScoreCardComponent } from './application-details/loanInformation/score-card/score-card.component';
import { UpdateApprovalComponent } from './application-details/loanInformation/revise-application/update-approval/update-approval.component';
import { IncomeProfileComponent } from './application-details/business/incomeProfile/income-profile.component';
import { FeePaymentComponent } from './application-details/loanInformation/feePayment/fee-payment.component';
import { XDataServiceComponent } from './application-details/applicationDocument/xdata-service/xdata-service.component';
import { ServicesModule } from './application-details/applicationDocument/xdata-service/xdata-services.module';
import { BureauComponent } from './application-details/applicationDocument/xdata-service/bureau-services/bureau.component';
import { EkycComponent } from './application-details/applicationDocument/xdata-service/kyc-services/ekyc.component';
import { DocketComponent } from './application-details/loanInformation/docket/docket.component';
import { GuarantorComponent } from './application-details/loanInformation/guarantor-info/guarantor-info.component';
import { KycVerificationComponent } from './application-details/applicationDocument/xdata-service/kyc-verification/kyc-verification.component';
import { MatIconModule } from '@angular/material';
import { FillAccountComponent } from './application-details/applicationDocument/fill-account/fill-account.component';
import { ActionItemsComponent } from './application-details/decisions/action-items/action-items.component';
import { CoLenderComponent } from './application-details/loanInformation/co-lender/co-lender.component';
import { PennyDropComponent } from './application-details/applicationDocument/xdata-service/penny-drop/penny-drop.component';
import { KycIdProofVerificationComponent } from './application-details/applicationDocument/xdata-service/kycevidence-verification/kyc-idproof-verification.component';
import { BankAccountVerificationComponent } from './application-details/applicationDocument/xdata-service/bankevidence-verification/bank-account-verification.component';
import { BookVoucherComponent } from './application-details/applicationDocument/xdata-service/book-voucher/book-voucher.component';
import { LoanDocumentComponent } from './application-details/loanInformation/loan-document/loan-document.component';
import { EsignComponent } from './application-details/applicationDocument/xdata-service/e-sign/e-sing.component';
import { BureauVerificationComponent } from './application-details/applicationDocument/xdata-service/bureauevidence-verification/bureau-verification.component';
import { ValidationErrorsComponent } from './application-details/validationError/validation-error.component';
import { BankAnalyzerComponent } from './application-details/applicationDocument/xdata-service/bank-analyzer/bank-analyzer.component';
import { DeleteCoApplicant } from './application-details/applicant/deleteCoApplicant/delete-coapplicant.component';
@NgModule({
    imports: [
        ApplicationsRoutingModule,
        BrowserModule,
        HttpClientModule,
        MaterialLibModule,
        FormsModule,
        RouterModule,
        FlexLayoutModule, 
        ReactiveFormsModule,
        AllPipesModule,
        NgxImageZoomModule,
        ImagePreviewDialogModule,
        FileUploadModule,
        ConfirmationDialogModule,
        GoToPageModule,
        ServicesModule,
        MatIconModule
    ],
    declarations: [
        ApplicationsComponent,
        ApplicationDetailsComponent,
        LoanRequestedComponent,
        LoanProposedComponent,
        BusinessInformationComponent,
        KYCProofsComponent,
        BusinessAddressesComponent,
        BusinessBankAccountsComponent,
        BusinessDocumentComponent,
        BusinessReferenceComponent,
        ApplicantInformationComponent,
        ApplicantKYCProofsComponent,
        ApplicantAddressComponent,
        ApplicantBankAccountsComponent,
        ApplicantDocumentComponent,
        ApplicantReferenceComponent,
        CollateralDetailsComponent,
        ColletralSummaryComponent,
        ColletralVerificationComponent,
        CFAComponent,
        SalesBillsComponent,
        GSTReturnsComponent,
        ITReturnsComponent,
        PurchaseBillsComponent,
        SalesBillsComponent,
        LiabilityComponent,
        BusinessExpensesComponent,
        FamilyIncomeComponent,
        FamilyExpensesComponent,
        ConditionsComponent,
        DeviationComponent,
        DecisionComponent,
        PreConditionComponent,
        BankStatementsComponent,
        PostDateChequeComponent,
        CheckListComponent,
        QuestionAnswerSystemComponent,
        ChangeRequestComponent,
        ApplicationTrailComponent,
        RepaymentAccountComponent,
        ApprovalPreConditionComponent,
        ChangeApprovalComponent,
        LoanFolderFilesComponent,
        BookLoanComponent,
        BureauReportsComponent,
        ReviseApplicationComponent,
        DedupCheckComponent,
        ScoreCardComponent,
        UpdateApprovalComponent,
        IncomeProfileComponent,
        FeePaymentComponent,
        XDataServiceComponent,
        BureauComponent,
        EkycComponent,
        DocketComponent,
        GuarantorComponent,
        KycVerificationComponent,
        FillAccountComponent,
        ActionItemsComponent,
        CoLenderComponent,
        PennyDropComponent,
        KycIdProofVerificationComponent,
        BankAccountVerificationComponent,
        BookVoucherComponent,
        LoanDocumentComponent,
        EsignComponent,
        BureauVerificationComponent,
        ValidationErrorsComponent,
        BankAnalyzerComponent,
        DeleteCoApplicant
    ],
    schemas:[CUSTOM_ELEMENTS_SCHEMA,NO_ERRORS_SCHEMA]
})
export class ApplicationModule { }