import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class IgFileService {

    private API = 'api/';

    constructor(private http: HttpClient) {
    }

    uploadFIle(file: File): Observable<any> {
        const uploadData = new FormData();
        uploadData.set('files', file, file.name);
        return this.http.post(this.API + 'file/upload', uploadData, { observe: 'response' });
    }

    uploadMultipleFIle(file: File[]): Observable<any> {
        const uploadData = new FormData();
        for ( let i = 0; i < file.length; i++) {
            uploadData.append('files', file[i]);
        }
        return this.http.post(this.API + 'file/upload', uploadData, { observe: 'response' });
    }

    downloadFile(fileType: string): Observable<any> {
        let params = new HttpParams;
        params = params.set('fileType', fileType);

        let type = '';
        fileType === 'excel' ? type = '.xlsx' : type = '.pdf';

        return this.http.get(this.API + '_downloads/sampleFiles', { params, observe: 'response', responseType: 'blob' }).pipe(
            map((resp: HttpResponse<Blob>) => {
                const out = { data: null, filename: null, type: null};
                out.data = resp.body;
                out.filename = 'sampleTemplateFile' + type;
                return out;
            })
        );
    }

    generateExcelFile(): Observable<any> {
        return this.http.get(this.API + '_downloads/generateExcel', {observe: 'response', responseType: 'blob' }).pipe(
            map((resp: HttpResponse<Blob>) => {
                const out = { data: null, filename: null, type: null};
                out.data = resp.body;
                out.filename = 'sampleExcelTemplateFile.xlsx';
                return out;
            })
        );
    }

    generatePdfFile(): Observable<any> {
        return this.http.get(this.API + '_downloads/generatePdf', {observe: 'response', responseType: 'blob' }).pipe(
            map((resp: HttpResponse<Blob>) => {
                const out = { data: null, filename: null, type: null};
                out.data = resp.body;
                out.filename = 'samplePdfTemplateFile.pdf';
                return out;
            })
        );
    }

    getAllFilesFromFolder (folderUid: string) {
        if(folderUid){
      return this.http.get<Files[]>(this.API + 'file/getAllFiles/'
        + folderUid, {observe: 'response'});
        }
    }

    fileStreamById(fileId: string, streamThumbImage: string) {
      const out = { data: null, filename: null };
      let params = new HttpParams;
      params = params.set('fileId', fileId);
      params = params.set('streamThumbImage', streamThumbImage);
      return this.http.get('api/file/stream', { params, observe: 'response', responseType: 'blob' })
          .pipe(map((res: HttpResponse<Blob>) => {
        const out = { data: null, filename: null, type: null};
        out.data = res.body;
        return out;
      }));
    }
}

export class Files {
    constructor (
        public uid ?: number,
        public fileName ?: string,
        public fileSize ?: number,
        public thumbImgFileSize ?: number,
        public type ?: string,
        public path ?: string,
        public source ?: string,
        public uploaderId ?: string,
        public fileId ?: string,
        public folderUid ?: string,
        public sequenceNumber ?: number,
        public thumbnailPath ?: string,
        public lat ?: number,
        public lng ?: number,
        public linkToType ?: string,
        public linkToId ?: string,
        public linkToUid ?: string,
        public contextType ?: string,
        public contextId ?: string,
        public contextUid ?: string,
        public fileContent ?: any,
        public imageLoaded ?: boolean 
    ) {}
}