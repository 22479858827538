export class Folder {
    constructor (
        public uid ?: string,
        public version? : Number,
        public type ?: string,
        public name ?: string,
        public contextType ?: string,
        public contextId ?: string,
        public contextUid ?: string,
        public linkToType ?: string,
        public linkToId ?: string,
        public linkToUid ?: string

    ) {}
}

export class File {
    constructor (
        public uid? : string,
        public fileName? : string,
        public fileSize ?: string,
        public thumbImgFileSize ?: string,
        public type ?: string,
        public path ?: string,
        public localFilePath ?: string,
        public source ?: string,
        public uploaderId ?: string,
        public fileId ?: string,
        public folderUid ?: string,
        public sequenceNumber ?: Number,
        public thumbnailPath ?: string,
        public lat ?: string,
        public lng ?: string,
        public linkToType ?: string,
        public linkToId ?: string,
        public linkToUid ?: string,
        public contextType ?: string,
        public contextId ?: string,
        public contextUid ?: string,
        public width ?: string,
        public height ?: string,
    ) {}
}

export class FolderFilesComposite {
    constructor (
        public folderInfo : Folder,
        public fileInfo? :  File[],
    ) {}
}