import { Component, OnInit, Input,Inject,HostListener } from '@angular/core';
import { IgFileService, Files } from '@ig-core/form/igFile.service';
import { File, Folder, FolderFilesComposite } from '@app/applications/application-details/loanInformation/loan-folder-files/loan-folder-files.model';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import {trigger, state, style, animate, transition} from '@angular/animations';

@Component({
  selector: 'eng-image-preview-dialog',
  templateUrl: './image-preview-dialog.component.html',
  styleUrls: ['./image-preview-dialog.component.scss'],
  animations: [
    // Each unique animation requires its own trigger. The first argument of the trigger function is the name
    trigger('rotatedState', [
      state('default', style({ transform: 'rotate(0)'})),
      state('uprotated', style({ transform: 'rotate(-90deg)'})),
      state('downrotated', style({ transform: 'rotate(-180deg)'})),
       state('rightrotated', style({ transform: 'rotate(-270deg)'})),
      transition('default => uprotated', animate('1000ms ease-out')),
      transition('uprotated => downrotated', animate('1000ms ease-out')),
       transition('downrotated => rightrotated', animate('1000ms ease-in')),
        transition('rightrotated => default', animate('1000ms ease-in'))
  ])
]
})
export class ImagePreviewDialogComponent implements OnInit {
 folderandFileDetails: FolderFilesComposite;
  documents:Array<File>=[]
  fullresolutionImage: any
  myThumbnail: any
  myFullresImage: any
  startHeight:number;
  startWidth:number;
  imageHeight:number;
  state: string = 'default';
  imageOriginCustom:string;
  constructor(public dialog: MatDialog, private fileService: IgFileService, @Inject(MAT_DIALOG_DATA) public data: {images:FolderFilesComposite}
  ,private mdDialogRef: MatDialogRef<ImagePreviewDialogComponent>) {
    this.folderandFileDetails=this.data.images
    this.test()
   }
  public cancel() {
    this.close(false);
  }
  public close(value) {
    this.mdDialogRef.close(value);
  }
  public confirm() {
    this.close(true);
  }
  @HostListener("keydown.esc")
  public onEsc() {
    this.close(false);
  }
  test(){
    this.data
  }
  ngOnInit() {
    this.thumbnailImage()
  }

  rotate() {
    this.imageOriginCustom="50% 50%"
    if (this.state === 'default') {
      this.state = 'uprotated'
    } else if (this.state === 'uprotated') {
      this.state = 'downrotated'
    } else if (this.state === 'downrotated') {
      this.state = 'rightrotated'
    } else if (this.state === 'rightrotated') {
      this.state = 'default'
    }else{
      this.state = 'default'
    }
  }

  fullImagePreview(fileId,width,height,index) {
    this.state = 'default'
    this.startHeight=parseInt(this.folderandFileDetails.fileInfo[index].height)
    this.startWidth=parseInt(this.folderandFileDetails.fileInfo[index].width)
    this.imageHeight =this.startWidth
    if(this.documents[index]["fullresolutionImage"] == undefined){
    this.fileService.fileStreamById(fileId,
      "false").subscribe(image => {
        let reader = new FileReader();
        reader.addEventListener("load", () => {
          this.fullresolutionImage = reader.result;
          this.documents[index]["fullresolutionImage"]=reader.result;
          this.compressImage(reader.result, width,height).then(compressed => {
            this.myThumbnail = compressed;
          })
          console.log("height", height)
        }, false);
        if (image) reader.readAsDataURL(image.data);
      });
    }else{
      this.fullresolutionImage=this.documents[index]["fullresolutionImage"] 
      this.compressImage(this.fullresolutionImage, width,height).then(compressed => {
        this.myThumbnail = compressed;
      })
    }
  } 

  // this function will generate one tumbnail using original image width and height.
  compressImage(src, newX,newY) {  
    return new Promise((res, rej) => {
      const img = new Image();
     
      img.src = src;
      img.onload = () => {
        const elem = document.createElement('canvas');
        elem.width = newX;
        elem.height = newY;
        const ctx = elem.getContext('2d');
        ctx.drawImage(img, 0, 0, newX, newY);
        const data = ctx.canvas.toDataURL();
        res(data);
      }
      img.onerror = error => rej(error);
    })
  }

  
thumbnailImage(){
  this.documents =this.folderandFileDetails.fileInfo
    //Fetch all FileDtos stored in the folder
          this.documents.forEach(document => {
            //stream all images found in the folder
            this.fileService.fileStreamById(document.fileId,
              "true").subscribe(image => {
                let reader = new FileReader();
                reader.addEventListener("load", () => {
                  document["fileContent"] = reader.result; //used in img [src]
                 // this.myThumbnail = reader.result;
                  document["imageLoaded"] = true;
                }, false);
                if (image) reader.readAsDataURL(image.data);
              });
          });
}
}
