import { animate, state, style, transition, trigger } from '@angular/animations';
import { AfterViewInit, Component, OnDestroy, OnInit,Input,SimpleChanges } from '@angular/core';
import { MediaObserver, MediaChange } from '@angular/flex-layout';
import { MatSnackBar } from '@angular/material';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { LeadDetails ,UpdateLead} from '../leads.model';
import { takeUntil } from 'rxjs/operators';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import { IgFileService } from '@ig-core/form/igFile.service';
import { Note } from '@app/note/note.model';
import { NoteService } from '@app/note/note.service';
import { FormGroup, FormBuilder, FormControl, FormArray } from '@angular/forms';
import { NameValueDto } from '@ig-core/interfaces/name-value-dto';
import { ReferenceCodeService } from '@app/admin/reference-code/reference-code.service';
import { BottomSheetService } from '@app/ig-layout/bottom-sheet/bottom-sheet.service';
import { LeadsService } from '../leads.service';
import { BranchService } from '@app/admin/branch/branch.service';
import { ImagePreviewDialogService } from '@app/utils/image-preview-dialog/image-preview-dialog.service';
import { RecentOpenedApplicationsService } from '@app/applications/recent-opened-applications.service';

@Component({
  selector: 'eng-lead-details',
  templateUrl: 'lead-details.template.html',
  styleUrls: ['lead-details.styles.scss'],
  animations :[
    trigger('showHideSidenav', [
      state('show', style({width: '330px'})),
      state('hide', style({width: '0px'})),
      transition('show <=> hide', animate('0.5s')),
    ]),
    trigger('shrinkExpandLeadDetails', [
      state('shrink', style({'margin-right': '330px'})),
      state('expand', style({'margin-right': '0px'})),
      transition('shrink <=> expand', animate('0.5s')),
    ])
  ]
})

export class LeadDetailsComponent implements OnInit, AfterViewInit, OnDestroy {
  
  @Input()
  leadDetails: LeadDetails;
  flexMediaWatcher: Subscription;
  //leadDetails: LeadDetails;
  updateLeadDetails:UpdateLead={};
  leadImage: any;
 
  businessNatureTypes: NameValueDto[];
  leadSourceTypes: NameValueDto[];
  productTypes: NameValueDto[];
  leadStatusTypes: NameValueDto[];
  followUpTypes: NameValueDto[];
  userBranches: NameValueDto[];
  leadCloseReasons: NameValueDto[];

  noteContent : string;
  currentScreenWidth: string = '';
  leadDetailsForm: FormGroup;
  
  isFormDisabled : boolean;
  isSmallScreen: boolean;
  showForm: boolean;
  imageLoaded: boolean;
  personImageDetails:any

  constructor(private activatedRoute: ActivatedRoute,
      private media: MediaObserver,
      private leadService: LeadsService, 
      private fileService: IgFileService,
      private noteService: NoteService,
      private bottomSheetService: BottomSheetService,
      private referenceCodeService: ReferenceCodeService,
      private _snackbar: MatSnackBar,
      private branchService: BranchService,
      private formBuilder: FormBuilder,
      private imagePreviewDialogService:ImagePreviewDialogService,
      private recentOpenedApplicationsService:RecentOpenedApplicationsService) {
    this.activatedRoute.data
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(response => {
        if(response.application){
          this.leadDetails = response.application.body;
        }
        
      },error => {
        this._snackbar.open("Failed to fetch lead details, " + error, "Close", {
          duration: 2000,
        })
        console.log(error);
    });

    this.flexMediaWatcher = media.media$.subscribe((change: MediaChange) => {
      if(change.mqAlias !== this.currentScreenWidth) {
        this.currentScreenWidth = change.mqAlias;
        this.isSmallScreen = media.isActive('lt-md');
      }
    });
  }

  ngOnInit() {
    this.recentOpenedApplicationsService.entityCreatedDate(this.leadDetails.opportunity.initiatedOn);
    this.isFormDisabled = true;
    if(this.leadDetails.opportunity) this.getRefCodes();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.leadDetails) {
      this.buildLeadDetailsForm();
    }
  }

  ngAfterViewInit() {
    this.loadLeadImage(this.leadDetails.opportunity.businessPicFileUid);
  }

  loadLeadImage(folderUid: string) {
    //Fetch all FileDtos stored in the folder
    this.fileService.getAllFilesFromFolder(folderUid).subscribe((response) => {
      if(response && response.body && response.body.length > 0) {
        this.personImageDetails=response.body
        //If response received then use fileId from response's first object to stream
        this.fileService.fileStreamById(response.body[0].fileId,
            "true").subscribe(image => {
          let reader = new FileReader();
          reader.addEventListener("load", () => {
            //"leadImage" is used in img src attr
            this.leadImage = reader.result;
            this.imageLoaded = true;
          }, false);
          if(image) reader.readAsDataURL(image.data);
        });
      }
    });
  }

  getRefCodes() {
    this.referenceCodeService.getShortRefCodes('business_nature')
        .subscribe((response: any) => {
      this.businessNatureTypes = response.business_nature;
    });
    this.referenceCodeService.getShortRefCodes('sourcing_code')
        .subscribe((response: any) => {
      this.leadSourceTypes = response.sourcing_code;
    });
    this.referenceCodeService.getShortRefCodes('loan_type')
        .subscribe((response: any) => {
      this.productTypes = response.loan_type;
    });
    this.referenceCodeService.getShortRefCodes('opportunity_status')
        .subscribe((response: any) => {
      this.leadStatusTypes = response.opportunity_status;
    });
    this.referenceCodeService.getShortRefCodes('followup_type')
        .subscribe((response: any) => {
      this.followUpTypes = response.followup_type;
    });
    this.branchService.getBranchesForUser().subscribe((response: any) => {
      if (response) {
        this.userBranches = response.body;
      }
    });
    this.referenceCodeService.getShortRefCodes('lead_close_reason')
        .subscribe((response: any) => {
      this.leadCloseReasons = response.lead_close_reason;
      this.buildLeadDetailsForm();
    });
    
  }

  buildLeadDetailsForm() {
    this.leadDetailsForm = this.formBuilder.group({
      ownerName: this.leadDetails.opportunity.ownerName,
      ownerMobileNumber: this.leadDetails.opportunity.ownerPrimaryPhone,
      businessName: this.leadDetails.opportunity.businessName,
      businessNature: this.leadDetails.opportunity.businessNature,
      businessAddressLine1: this.leadDetails.opportunity.addressLine1,
      businessAddressLine2: this.leadDetails.opportunity.addressLine2,
      businessAddressLine3: this.leadDetails.opportunity.addressLine3,
      pinCode: this.leadDetails.opportunity.pincode,
      leadSource: this.leadDetails.opportunity.source,
      isInterestedInLoan: this.leadDetails.opportunity.interestedInLoan,
      product: this.leadDetails.opportunity.product,
      amount: this.leadDetails.opportunity.amount,
      leadStatus: this.leadDetails.opportunity.status,
      plannedFollowupDate: this.leadDetails.plannedFollowup && 
        this.leadDetails.plannedFollowup.plannedDueDate ?
          new Date(this.leadDetails.plannedFollowup.plannedDueDate) : undefined,
      plannedFollowupType: this.leadDetails.plannedFollowup ?
        this.leadDetails.plannedFollowup.type : '',
      todaysInteractionType: '',
      todaysInteractionRemarks: '',
      nextFollowupDate: this.leadDetails.nextFollowup && 
        this.leadDetails.nextFollowup.plannedDueDate ?
          new Date(this.leadDetails.nextFollowup.plannedDueDate) : undefined,
      nextFollowupType: this.leadDetails.nextFollowup ?
        this.leadDetails.nextFollowup.type : '',
      leadClosed: this.leadDetails.opportunity.closedOn ? true : false,
      applicationFlag: this.leadDetails.opportunity.closedOn &&
        this.leadDetails.opportunity.applicationFlag ? true : false,
      leadCloseReasons : this.formBuilder.array([])
    });
      
    let leadCloseReasonsFormArray = this.leadDetailsForm.controls.leadCloseReasons as FormArray;
    let leadCloseReasonsGiven = this.leadDetails.opportunity.outcomeReason;
    console.log("flag",this.leadDetailsForm.value.applicationFlag)
      this.leadCloseReasons.forEach(reason => {
        if(leadCloseReasonsGiven) {
        if(leadCloseReasonsGiven.split(',').includes(reason.code)) {
          leadCloseReasonsFormArray.push(new FormControl(true));
          }else{
            leadCloseReasonsFormArray.push(new FormControl(false));
          }
        } else{
          leadCloseReasonsFormArray.push(new FormControl(false));
        }
      });

    /* After building form only, set showForm to true.
        Otherwise html will load form before it is built. */
    this.showForm = true;
  }

  updateLead() {
    if(!this.isFormDisabled && this.leadDetailsForm.valid) {
      let leadDetailsFormValues = this.leadDetailsForm.value;
      this.leadDetails.opportunity.ownerName = leadDetailsFormValues.ownerName;
      this.leadDetails.opportunity.ownerPrimaryPhone = leadDetailsFormValues.ownerMobileNumber;
      this.leadDetails.opportunity.businessName = leadDetailsFormValues.businessName;
      this.leadDetails.opportunity.businessNature = leadDetailsFormValues.businessNature;
      this.leadDetails.opportunity.addressLine1 = leadDetailsFormValues.businessAddressLine1;
      this.leadDetails.opportunity.addressLine2 = leadDetailsFormValues.businessAddressLine2;
      this.leadDetails.opportunity.addressLine3 = leadDetailsFormValues.businessAddressLine3;
      this.leadDetails.opportunity.pincode = leadDetailsFormValues.pinCode;
      this.leadDetails.opportunity.source = leadDetailsFormValues.leadSource;
      this.leadDetails.opportunity.interestedInLoan = leadDetailsFormValues.isInterestedInLoan;
      this.leadDetails.opportunity.product = leadDetailsFormValues.product;
      this.leadDetails.opportunity.amount = leadDetailsFormValues.amount;
      this.leadDetails.opportunity.status = leadDetailsFormValues.leadStatus;
      this.updateLeadDetails["opportunity"]= this.leadDetails.opportunity,
      this.updateLeadDetails["type"]=this.leadDetailsForm.controls.todaysInteractionType.value
     
      this.updateLeadDetails["remarks"]=this.leadDetailsForm.controls.todaysInteractionRemarks.value
    
      this.leadService.saveLead(this.updateLeadDetails).toPromise().then(
        (success) => {
          this._snackbar.open("Lead updated successfully", "Close", {
            duration:2000,
          });
          this.leadService.getLeadDetails(this.leadDetails.opportunity.uid)
            .subscribe(response => {
              this.leadDetails = response.body;
              this.isFormDisabled = true;
              this.buildLeadDetailsForm();
            },
            error => {
              this._snackbar.open("Failed to fetch lead details, " + error, "Close", {
                duration: 2000,
              });
              console.log(error);
          });
        },
        (failure) => {
          this._snackbar.open("Lead update failed, " + failure, "Close", {
            duration: 2000,
          })
          console.log(failure);
        }
      );
    }
  }

  saveNote() {
    if(this.noteContent) {
      let note : Note = {
        linkToUid : this.leadDetails.opportunity.uid,
        linkToType : 'opportunity',
        content : this.noteContent
      }
      this.noteService.saveNote(note).toPromise().then(
        (success) => {
          this._snackbar.open("Note updated successfully", "Close" ,{
            duration:2000,
          });
          this.noteContent = '';
          this.getNotes(this.leadDetails.opportunity.uid);
        },
        (failure) => {
          this._snackbar.open("Note update failed, " + failure, "Close", {
            duration: 2000,
          })
          console.log(failure);
        }
      )
    }
  }

  getNotes(leadUid) {
    this.noteService.getNote({linkToType: 'opportunity',
      linkToUid: leadUid}).subscribe((response) => {
        this.leadDetails.notes = response.body;
    });
  }

  enableForm() {
    this.isFormDisabled = false;
  }

  cancelForm() {
    this.isFormDisabled = true;
    this.buildLeadDetailsForm();
  }

  ngOnDestroy(): void {
  }

  // this is used to view the image on click of the displayed image
  openImageViewElement() {
    let fileDetails=  {
      folderInfo : {},
      fileInfo :  [],
    }
    fileDetails.fileInfo=this.personImageDetails
   this.imagePreviewDialogService.open({ "images": fileDetails})
 }

}