import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material';
import { FormBuilder, FormGroup } from '@angular/forms';
import { FormErrorModel } from '@ig-core/form/form-error.model';
import { IgFormService } from '@ig-core/form/form.service';
import { takeUntil } from 'rxjs/operators';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import { NameValueDto } from '@ig-core/interfaces/name-value-dto';
import { ReferenceCodeService } from '@app/admin/reference-code/reference-code.service';
import { DeviationApproval } from '../deviation-approval.model';
import { DeviationApprovalService } from '../deviation-approval.service';

@Component({
  selector: 'app-deviation-approval',
  templateUrl: './deviation-approval-edit.template.html',
  styleUrls: ['../deviation-approval.style.scss'],
})

export class DeviationApprovalEditComponent implements OnInit, OnDestroy {

  deviationApproval: DeviationApproval;
  editable = true;
  title: string;
  mode: string;
  deviationcodes: Array<any> = [];
  deviationcodesAsIndex: any = []
  deviationCategories: NameValueDto[];

  public deviationApprovalEditForm: FormGroup;
  public formError: FormErrorModel;
  public formErrors = {
    itemType: '',
    approver: '',
    grant: '',
    approvalAmount: ''
  };

  constructor(
    private deviationApprovalService: DeviationApprovalService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private referenceCodeService: ReferenceCodeService,
    private formBuilder: FormBuilder,
    private _snackbar: MatSnackBar,
    private igFormService: IgFormService
  ) {
    this.activatedRoute.data
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(data => {
        if (data.deviationApproval === undefined) {
          this.deviationApproval = {};
        } else {
          this.deviationApproval = data.deviationApproval.body;
        }
        this.editable = data.editable;
      });
  }

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe(params => {
      let data = JSON.parse(params.data)
      this.title = data.title;
      this.mode = data.mode;
      if (data.mode === 'create') {
        this.deviationApproval = {};
      } else if (data.mode === 'edit') {
        this.deviationApproval = data.deviationApproval;
      } else if (data.mode === 'view') {
        this.deviationApproval = data.deviationApproval;
      }
    })
    this.getReferenceCodes();
    this.buildForm();
    this.formError = new FormErrorModel(false, '');
  }

  ngOnDestroy(): void { }

  buildForm() {
    this.deviationApprovalEditForm = this.formBuilder.group({
      uid: [this.deviationApproval.uid, []],
      grantType: [this.deviationApproval.grantType === undefined ? 'deviation' : this.deviationApproval.grantType, []],
      approverType: [this.deviationApproval.approverType === undefined ? 'role' : this.deviationApproval.approverType, []],
      deviationType: [this.deviationApproval.itemType, []],
      itemCode: [this.deviationApproval.itemCode, []],
      approver: [this.deviationApproval.approverCode, []],
      grant: [this.deviationApproval.allowdenyFlag, []],
    });
    if (this.deviationApproval.itemType) {
      const codes = this.deviationcodes.filter(item => item.parentReferenceCode === this.deviationApproval.itemType)
      this.deviationcodesAsIndex = codes;
    }
    if (this.mode === 'view') {
      this.deviationApprovalEditForm.disable();
    }
    this.deviationApprovalEditForm.controls.approverType.disable();
    this.deviationApprovalEditForm.controls.grantType.disable();
    this.deviationApprovalEditForm.valueChanges
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(data => {
        this.formErrors = this.igFormService.validateForm(
          this.deviationApprovalEditForm,
          this.formErrors,
          true
        );
      });
  }

  getReferenceCodes() {
    this.referenceCodeService.getShortRefCodes('deviation_type').subscribe((response: any) => {
      this.deviationCategories = response.deviation_type;
    });
    this.referenceCodeService.getRefCodesForClassifier('deviation_item').subscribe((response: any) => {
      this.deviationcodes = response;
    });
  }

  private markFormGroupTouched(formGroup: FormGroup) {
    (<any>Object).values(formGroup.controls).forEach(control => {
      control.markAsTouched();
      if (control.controls) {
        this.markFormGroupTouched(control);
      }
    });
  }

  saveDeviationApproval() {
    this.markFormGroupTouched(this.deviationApprovalEditForm)
    if (this.deviationApprovalEditForm.valid) {
      let deviationApprovalFormFields = this.deviationApprovalEditForm.value;
      this.deviationApproval.uid = deviationApprovalFormFields.uid;
      this.deviationApproval.grantType = deviationApprovalFormFields.grantType === undefined ? 'deviation' : this.deviationApproval.grantType;
      this.deviationApproval.approverType = deviationApprovalFormFields.approverType === undefined ? 'role' : this.deviationApproval.approverType;
      this.deviationApproval.itemType = deviationApprovalFormFields.deviationType;
      this.deviationApproval.itemCode = deviationApprovalFormFields.itemCode;
      this.deviationApproval.approverCode = deviationApprovalFormFields.approver;
      this.deviationApproval.allowdenyFlag = deviationApprovalFormFields.grant;
      this.deviationApprovalService.saveDeviationApproval(this.deviationApproval.uid,
        this.deviationApproval).toPromise().then(
          (_success) => {
            if (this.deviationApproval.uid) {
              this._snackbar.open("Deviation Approval updated successfully", "Close", {
                duration: 2000,
              });
              this.router.navigate(['../../'], { relativeTo: this.activatedRoute });
            } else {
              this._snackbar.open("Deviation Approval created successfully", "Close", {
                duration: 2000,
              });
              this.router.navigate(['../'], { relativeTo: this.activatedRoute });
            }
          }, (failure) => {
            this._snackbar.open("Deviation Approval update failed, " + failure, "Close", {
              duration: 2000,
            })
            console.log(failure);
          }
        );
    }
  }

  checkValue(event) {
    if (this.deviationcodes.length > 0) {
      const codes = this.deviationcodes.filter(item => item.parentReferenceCode === event.value)
      this.deviationcodesAsIndex = codes;
    }
  }

  goToDeviationApprovalListPage() {
    if (this.deviationApproval.uid) {
      this.router.navigate(['../../'], { relativeTo: this.activatedRoute });
    } else {
      this.router.navigate(['../'], { relativeTo: this.activatedRoute });
    }
  }

}
