import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import { takeUntil } from 'rxjs/operators';
import { FormGroup, FormBuilder, FormControl, Validators, FormArray } from '@angular/forms';
import { NameValueDto } from '@ig-core/interfaces/name-value-dto';
import { MatSnackBar } from '@angular/material';
import { ReportLink } from '../reports.model';
import { ReportService } from '../reports.service';
import { HttpResponse } from '@angular/common/http';
import { RecentOpenedApplicationsService } from '@app/applications/recent-opened-applications.service';
import { DateFormatPipe } from '@app/utils/date-format.pipe';
import { ApplicationService } from '@app/applications/application.service';
import { Folder, FolderFilesComposite } from '@app/applications/application-details/loanInformation/loan-folder-files/loan-folder-files.model';

@Component({
    selector: 'eng-report-criteria',
    templateUrl: 'report-criteria.template.html',
    styleUrls: ['report-criteria.styles.scss']
})
export class ReportCriteriaComponent implements OnInit, OnDestroy {

    reportLink: ReportLink;
    allFoldersData: Folder[] = [];
    allFilesInFolder: FolderFilesComposite;

    dynamicReportCriteriaForm: FormGroup;
    showForm: boolean;
    selectOptionsArray: NameValueDto[][] = [];

    constructor(private reportService: ReportService,
        private activatedRoute: ActivatedRoute,
        private formBuilder: FormBuilder,
        private _snackbar: MatSnackBar,
        private customDatepipe: DateFormatPipe,
        private recentOpenedApplicationsService:RecentOpenedApplicationsService,
        private applicationService: ApplicationService) {
          this.dynamicReportCriteriaForm = new FormGroup({
            newFolderName: new FormControl('')
          });
    }

    ngOnInit() {
      this.activatedRoute.queryParams
        .pipe(takeUntil(componentDestroyed(this)))
        .subscribe((params) => {
          this.reportLink = JSON.parse(params.reportLink) as ReportLink;
          this.dynamicReportCriteriaForm = this.formBuilder.group({
            newFolderName: '',
          });
          if(this.reportLink.reportType === 'dynamic') {
            this.reportService.getReportParameters(this.reportLink)
              .subscribe((response) => {
                this.reportLink = response.body;
                this.showForm = this.reportLink.inputParameters &&
                    this.reportLink.inputParameters.length > 0;
                if(this.showForm) {
                  //this getReportRecentData is used to pre-populate locally stored inputParameter data  
                  let recentReportData = this.recentOpenedApplicationsService.getReportsRecentData(this.reportLink);
                  if(recentReportData) {
                    //this condition is used to check if number of inputParameter from the server is either changed or new input fields are added or deleted 
                    //then we are comparing based on inputParameter name. If the field name matches then we will set localStored data to inputParameter 
                    //or else we will show the server inputParameter field and values 
                    for (let i = 0; i < this.reportLink.inputParameters.length; i++) {
                      for (let j = 0; j < recentReportData.inputParameters.length; j++) {
                        if (this.reportLink.inputParameters[i].name === recentReportData.inputParameters[j].name) {
                          this.reportLink.inputParameters = recentReportData.inputParameters;
                          break;
                        }
                      }
                    }
                  }
                  this.fetchSelectOptions();
                  this.buildForm();
                }
            });
          }
      });
      this.getDocket()
    }

    // this function is used to get the docket folder names to add report to docket we are checking for category 
    // because add to docket is only for documentation reports 
    getDocket() {
      if(this.reportLink.category === 'document'){
      this.applicationService.getDocketFolders(this.reportLink.contextUid).subscribe(response => {
        this.allFoldersData = response.body
      })
    }
    }

    fetchSelectOptions() {
      for(let i=0; i<=10; i++) {
        this.selectOptionsArray.push(undefined);
      }
      this.reportLink.inputParameters.forEach(parameter => {
        let parameterType = parameter.name.split('_')[1];
        let parameterIndex;
        if(parameterType === 'select' || parameterType === 'check'
            || parameterType === 'radio') {
          if(parameterType === 'select') {
            if (parameter.selectField1 !== null && parameter.selectField1 !== undefined) {
              parameterIndex = 1;
            } else if (parameter.selectField2 !== null && parameter.selectField2 !== undefined) {
              parameterIndex = 2;
            }else if (parameter.selectField3 !== null && parameter.selectField3 !== undefined) {
              parameterIndex = 3;
            }else if (parameter.selectField4 !== null && parameter.selectField4 !== undefined) {
              parameterIndex = 4;
            }else if (parameter.selectField5 !== null && parameter.selectField5 !== undefined) {
              parameterIndex = 5;
            }
          }
          this.reportService.getParameterOptions(this.reportLink,
            parameterType, parameterIndex).subscribe((response: any) => {
              this.selectOptionsArray[parameterIndex] = response.body;
          });
        }
      });
    }

    buildForm() {
      this.reportLink.inputParameters.forEach(parameter => {
        this.dynamicReportCriteriaForm.addControl(
          parameter.name,  new FormControl(parameter.textField || parameter.dateField || parameter.selectField1 || parameter.selectField2 || parameter.selectField3 || parameter.selectField4 || parameter.selectField5 , Validators.required));
          parameter.isMulti, new FormControl(parameter.isMulti)
      });
    }

    // this function is to set input values entered in the form 
    updateInputValues(){
      if(this.dynamicReportCriteriaForm.valid){
        this.reportLink.inputParameters.forEach(parameter => {
          if(parameter.textField !== null) {
            parameter.textField = this.dynamicReportCriteriaForm
              .get(parameter.name).value;
          } else if(parameter.selectField1 !== null) {
            parameter.selectField1 = this.dynamicReportCriteriaForm
              .get(parameter.name).value;
          } else if(parameter.selectField2 !== null) {
            parameter.selectField2 = this.dynamicReportCriteriaForm
              .get(parameter.name).value;
          } else if(parameter.selectField3 !== null) {
            parameter.selectField3 = this.dynamicReportCriteriaForm
              .get(parameter.name).value.filter(option => option !== 'all');
          } else if(parameter.selectField4 !== null) {
            parameter.selectField4 = this.dynamicReportCriteriaForm
              .get(parameter.name).value;
          } else if(parameter.selectField5 !== null) {
            parameter.selectField5 = this.dynamicReportCriteriaForm
              .get(parameter.name).value;
          } else if(parameter.dateField !== null) {
            parameter.dateField = this.dynamicReportCriteriaForm
              .get(parameter.name).value;
          } 
        });
        this.reportLink.inputParameters.forEach(parameter => {
          parameter.dateField = this.customDatepipe.transform(
              parameter.dateField,'DATEYMD');
        });
      }
    }

  // this function is to add report to docket 
    addReportToDocket(){
      if(this.dynamicReportCriteriaForm.valid){
      let folderUid = this.dynamicReportCriteriaForm.controls.newFolderName.value
      if(!folderUid){
        this._snackbar.open("Please select docket" , "close", {
          duration: 5000,
        })
        return
      }
      this.updateInputValues()
      this.applicationService.addToDocket(this.reportLink.contextUid, folderUid, this.reportLink).subscribe(response => {
        this.allFilesInFolder = response.body;
        this._snackbar.open("File added to docket " + this.allFilesInFolder.folderInfo.name , "close", {
          duration: 5000,
        });
         //this addReportRecentData is used to store entered inputParameter data locally on click of execute
         this.recentOpenedApplicationsService.addReportInputParameter(this.reportLink)
      })
    }
  }

    generateReport() {
      if(this.dynamicReportCriteriaForm.valid){
      this.updateInputValues();
        //this addReportRecentData is used to store entered inputParameter data locally on click of execute
        this.recentOpenedApplicationsService.addReportInputParameter(this.reportLink)
        this.reportService.generateReport(this.reportLink)
            .pipe(takeUntil(componentDestroyed(this))).subscribe(response => {
          this.saveDownloadedFile(response);
        }, error => {
          this.onDownloadError(error);
        }, () => {
        });
      }
    }

  saveDownloadedFile(response: HttpResponse<any>) {
    const url = window.URL.createObjectURL(response.body);
    const a = document.createElement("a");
    document.body.appendChild(a);
    a.setAttribute("style", "display: none");
    a.href = url;
    a.download =
      this.reportLink.reportOutputFileName +
      "." +
      this.reportLink.reportOutputType;
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();
  }

    onDownloadError(error: any) {
      console.log('download error:', JSON.stringify(error));
      this._snackbar.open("Error downloading report, " + error, "Close", {
        duration: 4000,
      });
    }

    // this method is used to handel selece "all" option
    handleAllOption(index) {  
      if (this.reportLink.inputParameters[index].isMulti) {
      let formControl = this.dynamicReportCriteriaForm.get(this.reportLink.inputParameters[index].name);

        if (formControl.value.includes('all')) {
          // If "ALL" is selected, select all other options
          let allBranchCodes = this.selectOptionsArray[index + 1].map(branch => branch.code);
          formControl.setValue(allBranchCodes.concat('all' as any));
        } else {
          // If "ALL" is not selected, set the form value with empty, this will deselect all the options
          formControl.setValue([]);
        }
      }
    }

    // this method is used to handel individual option selection, on click of all options "all" will also be selected
    // and on deselecting any one option "all" will also gets deselected.
    selectedValues(index){
      if(this.reportLink.inputParameters[index].isMulti){
        let formControl = this.dynamicReportCriteriaForm.get(this.reportLink.inputParameters[index].name);
      // this filter we are doing to uncheck "all" option if we uncheck any one value after clicking "all"
      let updatedSelection = formControl.value.filter(option => option !== 'all');
      formControl.setValue(updatedSelection);

      // this we are checking beacause if we select all the dropdown values that is nothing but selectAll option
      // hence adding "all" so that option "all" will be checked
      if (updatedSelection.length === this.selectOptionsArray[index + 1].length) {
        updatedSelection = updatedSelection.concat('all');
        formControl.setValue(updatedSelection);
      }
    } 
  }
  
    ngOnDestroy(): void {
    }
}