  export class FeePaymentDto {
    constructor(
        public uid?: string,
        public version?: string,
        public chequeDate?: Date,
        public chequePayto?: string,
        public purpose?: string,
        public accountNo?: string,
        public accountType?: string,
        public accountHolderName?: string,
        public bankName?: string,
        public branchName?: string,
        public chequeScanFolder?: string,
        public status?: string,
        public contextType?: string,
        public contextId?: string,
        public contextUid?: string,
        public paymentMethod?: string,
        public referenceNumber?: string,
        public amount?: number,
        public subCategory?: string,
        public category?: string,
        public voucherReference?: string
    ) { }
  }
