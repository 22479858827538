import { Component, OnInit } from '@angular/core';
import { DashboardService} from '../dashboard.service';
import { Router} from '@angular/router';
import { BranchService } from '@app/admin/branch/branch.service';
import { NameValueDto } from '@app/loan-od-accounts/name-value-dto';
import { FormBuilder, FormGroup } from '@angular/forms';
@Component({
  selector: 'eng-tat-matrix',
  templateUrl: './tat-matrix.component.html',
  styleUrls: ['./tat-matrix.component.scss', '../dashboard.component.scss']
})
export class TatMatrixComponent implements OnInit {
  dashboardData:any;
  hideTables:boolean=false;
  userBranches: NameValueDto[];
  fieldSearchForm: FormGroup;  
  constructor(private dashboardService:DashboardService,private router:Router,
    private formBuilder: FormBuilder ,private branchService:BranchService) { }

  ngOnInit() {
      // Initialize the form with empty values and default branch
    this.fieldSearchForm = this.formBuilder.group({
      login: '',
      branches: '' // Set the default value here
    });
    this.getUserBranches();
    this.getDashboardData();
  }

  //get Dashboard related data

getDashboardData(){
  let branches = this.fieldSearchForm.value.branches;
  const login = this.fieldSearchForm.value.login;

  // if user select all and click on show then it will send option 'all' as well,
  // hence we are removing 'all' from selected branches if 'all' is included
  if (branches.includes('all')) {
    branches = branches.filter(option => option !== 'all');
  }
  this.dashboardService.getDashboradDataTATmatrix(branches, login).subscribe(response=>{
  this.dashboardData =response.body
  console.log("TATData",this.dashboardData)
  if(this.dashboardData.status == "failure"){
    this.hideTables=true
  }
  })
}

goToApplicationList(item){
  let searchCriteria ={"applicationDateFrom":item.submissionDate,"show":"ALL","stages":[]}
  this.dashboardService.updateSearchCriteria(searchCriteria)
  this.router.navigateByUrl(`applications`)
}

// Fetch the branches available for the user
getUserBranches() {
  this.branchService.getBranchesForUser().subscribe((response: any) => {
    if (response) {
      this.userBranches = response.body;
    }
  });
}

  handleAllOption() {
    let selectedOptions = this.fieldSearchForm.value.branches;

    if (selectedOptions.includes('all')) {
      // If "ALL" is selected, select all other options
      let allBranchCodes = this.userBranches.map(branch => branch.code);
      let selectedValues = allBranchCodes.concat('all');
      this.fieldSearchForm.get('branches').setValue(selectedValues);
    }
    else {
      // If "ALL" is not selected, set the form value with empty, this will deselect all the options
      this.fieldSearchForm.get('branches').setValue([]);
    }
  }

  selectedValues(){
    let selectedOptions = this.fieldSearchForm.value.branches;

    // this filter we are doing to uncheck "all" option if we uncheck any one value after clicking "all"
    let updatedSelection = selectedOptions.filter(option => option !== 'all');
    this.fieldSearchForm.get('branches').setValue(updatedSelection);

    // this we are checking beacause if we select all the dropdown values that is nothing but selectAll option
    // hence adding "all" so that option "all" will be checked
    if (updatedSelection.length === this.userBranches.length) {
      let selectedValue = selectedOptions.concat('all');
      this.fieldSearchForm.get('branches').setValue(selectedValue);
    } 
  }
}
