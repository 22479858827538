import { Component, OnInit, Input } from '@angular/core';
import { BankAccounts } from '../../../applications.model';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ApplicationService } from '@app/applications/application.service';
import { LoanApplication,ApplicationAccessSpecifier } from '@app/applications/applications.model';
import { ReferenceCodeService } from '@app/admin/reference-code/reference-code.service';
import { NameValueDto } from '@ig-core/interfaces/name-value-dto';
import { MatSnackBar } from '@angular/material';
import { BusinessEntity } from '@app/leads/leads.model';
//validation code
import { ValidationService } from '../../../application-validators/validation.service';
import { ConfirmationDialogService } from '@app/utils/confirmation-dialog/confirmation-dialog.service';
import { BankAccountVerificationResponseComposite } from './business-bank-accounts.model';
@Component({
  selector: 'eng-business-bank-accounts',
  templateUrl: 'business-bank-accounts.template.html',
  styleUrls: ['../../application-details.styles.scss']
})
export class BusinessBankAccountsComponent implements OnInit {

  @Input()
  application: LoanApplication;
  @Input()
  menuCode:string;

  @Input()
  business: BusinessEntity;

  businessBankAccounts: BankAccounts[];
  businessBankAccountsForm: FormGroup;
  selectedBankAccount: BankAccounts;
  accountTypes: NameValueDto[];

  isFormDisabled: boolean;
  showForm: boolean;
  allowAccess:Boolean;
  menuItemAllowAccess:boolean;

  applicationAccessSpecifiers: ApplicationAccessSpecifier[];
  menuItemAccessSpecifier: ApplicationAccessSpecifier;
  bankAccountVerificationResponse: BankAccountVerificationResponseComposite
  // this is used to set duplicate of businessBankAccounts response value which we get on api call
  businessBankAccountsPrestine: BankAccounts[] = [];

  constructor(private formBuilder: FormBuilder,
    private referenceCodeService: ReferenceCodeService,
    private applicationService: ApplicationService,
    private _snackbar: MatSnackBar,
    private confirmationDialogService:ConfirmationDialogService,
    private validationService: ValidationService) {
      this.businessBankAccountsForm = this.formBuilder.group({
        accountType: '',
        accountNumber: '',
        accountHolderName: '',
        branchName: '',
        bankName: '',
        ifsc:'',
        bankingSince: ''
      });
      this.allowAccess = this.applicationService.allowAccess;
  }

  ngOnInit(): void {
    this.getMenuItemAccess();
    this.isFormDisabled = true;
    this.getRefCodes();
    this.applyValidatorsToBusinessBankAccounts();
   
  }

  getRefCodes() {
    this.referenceCodeService.getShortRefCodes('business_bankac_type').subscribe((response: any) => {
      this.accountTypes = response.business_bankac_type;
    });
  }

  fetchbusinessBankAccounts() {
    this.showForm = false;
    this.applicationService.getBusinessBankAccounts(
      this.application.uid).subscribe((response: any) => {
        if(response) {
          this.businessBankAccounts = response.body;
          // taking copy of the response in businessBankAccountsPrestine, when click on close we are setting back businessBankAccountsPrestine to businessBankAccounts
          this.businessBankAccountsPrestine = JSON.parse(JSON.stringify(this.businessBankAccounts));
          this.businessBankAccounts.forEach((element,index) => {
            this.businessBankAccounts[index]["deleteBtnDisabled"]=true
          });
          this.showForm = (this.businessBankAccounts && this.businessBankAccounts.length > 0);
          this.buildBusinessBankAccountForm();
        }
    });
  }

  addNewBankAccount() {
      this.showForm = true;
      this.isFormDisabled = false;
      this.changeSelectedAccounts(new BankAccounts(),undefined,"new");
      
  }

  changeSelectedAccounts(businessBankAccount ?: BankAccounts,index?:number,from?:string) {
    this.showForm = true;
    this.businessBankAccounts.forEach((element,index) => {
      this.businessBankAccounts[index]["deleteBtnDisabled"]=true
    });
    if(from != "new"){
      this.businessBankAccounts[index].deleteBtnDisabled =false
      }
     
    this.buildBusinessBankAccountForm(businessBankAccount);
  }

  buildBusinessBankAccountForm(bankAccount ?: BankAccounts) {
    this.bankAccountVerificationResponse = null
    if(this.showForm) {
      if(!bankAccount) {
        bankAccount = this.businessBankAccounts[0];
        this.businessBankAccounts[0].deleteBtnDisabled =false
      }
     // this.businessBankAccountsForm = this.formBuilder.group({
       this.businessBankAccountsForm.patchValue({
        accountType: bankAccount.accountType,
        accountNumber: bankAccount.accountNumber,
        accountHolderName: bankAccount.accountHolderName,
        branchName: bankAccount.branchName,
        bankName: bankAccount.bankName,
        ifsc: bankAccount.ifsc,
        bankingSince: bankAccount.bankingSince
      });
      this.selectedBankAccount = bankAccount;
      // we are calling getBankAccountVerificationEvidence only if the selected document is verified,
      // and if it has serviceProvidedData then only we are displaying the electronic verification reference card
      if(this.selectedBankAccount.verifiedFlag){
        this.applicationService.getBankAccountVerificationEvidence(this.application.uid,this.selectedBankAccount.uid).subscribe(response =>{
          if(response.body.serviceProviderData){
          this.bankAccountVerificationResponse = response.body
          }
        })
      }
    }
  }

  saveBankAccount() {
    this.validationService.markFormGroupTouched(this.businessBankAccountsForm)
    if(!this.isFormDisabled && this.businessBankAccountsForm.valid){
      if(!this.selectedBankAccount.uid) {
        this.selectedBankAccount.linkToType = 'entity';
        this.selectedBankAccount.linkToUid = this.business.uid;
        this.selectedBankAccount.linkedAs = 'business';
        this.selectedBankAccount.contextType = 'application';
        this.selectedBankAccount.contextUid = this.application.uid;
      }
      let businessBankAccountFormFields = this.businessBankAccountsForm.value;
      this.selectedBankAccount.accountType = businessBankAccountFormFields.accountType;
      this.selectedBankAccount.accountNumber = businessBankAccountFormFields.accountNumber;
      this.selectedBankAccount.accountHolderName = businessBankAccountFormFields.accountHolderName;
      this.selectedBankAccount.branchName = businessBankAccountFormFields.branchName;
      this.selectedBankAccount.bankName = businessBankAccountFormFields.bankName;
      this.selectedBankAccount.ifsc = businessBankAccountFormFields.ifsc;
      this.selectedBankAccount.bankingSince = businessBankAccountFormFields.bankingSince;
      this.applicationService.saveBusinessBankAccount(this.application.uid,
        this.business.uid, this.selectedBankAccount).toPromise().then(
          (_success) => {
            this._snackbar.open("Business bank account updated successfully", "Close", {
              duration: 2000,
            });
            this.isFormDisabled = true;
            this.fetchbusinessBankAccounts();
           
          },(failure) => {
            let errormesg =[]
            errormesg.push("Business bank account update failed")
            errormesg.push(failure)
            this.applicationService.displayErrorMessages(errormesg);
          console.log(failure);
        }
      );
    }
  }

  enableDisableForm() {
    //if verifiedFlag is true then we are not allowing user to edit verified bankAccount details,hence disabling the fields
    if(this.selectedBankAccount.verifiedFlag == true){
      this.isFormDisabled = true;
      this._snackbar.open("Bank account already verified. Edit not allowed", "close" ,{
        duration: 5000
      })
      return;
    }
    this.isFormDisabled = false;
  }
  
  cancelForm() {
    // we are setting back the businessBankAccountsPrestine value to businessBankAccounts and building the form to get the previous value.
    this.businessBankAccounts = JSON.parse(JSON.stringify(this.businessBankAccountsPrestine));
    this.businessBankAccounts.forEach((element, i) => {
      this.businessBankAccounts[i].deleteBtnDisabled = true;
    });
    if(this.businessBankAccounts && this.businessBankAccounts.length > 0) {
      this.buildBusinessBankAccountForm();
    } else {
      this.showForm = false;
    }
    this.isFormDisabled = true;
  }
  //this function will apply validators to form group
  applyValidatorsToBusinessBankAccounts() {
    this.validationService.applyValidationRules(this.businessBankAccountsForm,"BusinessBankAccount").then((controlValidators) => {
      this.fetchbusinessBankAccounts();
    }).catch(() => {
    })
  }

  //this function will get either this menu item  is editable or readonly 
  getMenuItemAccess(){
    this.applicationService.getApplicationAccessModifiers(this.application.uid)
        .subscribe((response) => {
          this.applicationAccessSpecifiers = response.body;
          this.menuItemAccessSpecifier = this.applicationAccessSpecifiers
            .find(accessSpecifier => accessSpecifier.category === this.menuCode);
          if(this.menuItemAccessSpecifier){
          this.menuItemAllowAccess = this.menuItemAccessSpecifier.allowAccess;
            }
      });
    }


    //this function will be called on click of delete icon on collatral list
deleteBankAccount(selectedBankAccount){
  this.applicationService.bankAccountDelete(this.application.uid, selectedBankAccount.uid).subscribe((response) => {
    if(response.body.status == "success") {
    this._snackbar.open("Bank acccount deleted successfully", "Close", {
      duration: 2000,
    });
    this.fetchbusinessBankAccounts();
  } else {
    let errormesg =[]
    errormesg.push("Bank acccount deleted failed")
    errormesg.push(response.body.message)
    this.applicationService.displayErrorMessages(errormesg);
  }}, (failure) => {
    let errormesg =[]
    errormesg.push("Bank acccount deleted failed")
    errormesg.push(failure)
    this.applicationService.displayErrorMessages(errormesg);
  });
}

openDeleteConfirmation(){
  this.confirmationDialogService.open({ "btnCancelText":"Cancel","btnConfirmText":"Delete","message":"Are you sure you want to delete this Bank acccount?"})
  this.confirmationDialogService.confirmed().subscribe(data=>{
    if(data){
    this.deleteBankAccount(this.selectedBankAccount)
    }
  })
}
}

