export class BusinessIncomeSummary {
  constructor(
    public businessIncome ?: BusinessIncome[],
    public businessIncomeDetail ?: BusinessIncomeDetail[],
  ) { }
}

export class BusinessIncomeType {
  constructor(
    public businessIncome ?: BusinessIncome,
    public businessIncomeDetail ?: BusinessIncomeDetail,
  ) { }
}

export class BusinessIncome {
  constructor(
    public uid ?: string,
    public category ?: string,
    public description ?: string,
    public incomeDocumentType ?: string,
    public useInCfaFlag ?: boolean,
    public monthlySales ?: number,
    public salesMargin ?: number,
    public incomeMultiplier ?: number,
    public eligibleMonthlyTurnover ?: number,
    public dsrRatio ?: number,
    public preCalValues ?: PreCalValues,
    public frequency ?: string,
    public pmAmount ?: number,
    public pqAmount ?: number,
    public phAmount ?: number,
    public pyAmount ?: number,
    public mmyyStart ?: Date,
    public frozenFlag ?: boolean,
    public contextType ?: string,
    public contextId ?: string,
    public contextUid ?: string,
    public linkToType ?: string,
    public linkToId ?: string,
    public linkToUid ?: string,
    public version ?: number
  ) { }
}

export class BusinessIncomeDetail {
  constructor(
    public uid ?: string,
    public category ?: string,
    public description ?: string,
    public sourceOfIncome ?: boolean,
    public incomeDocumentType ?: string,
    public incomeDocumentSection ?: string,
    public frequency ?: string,
    public pmAmount ?: number,
    public pqAmount ?: number,
    public phAmount ?: number,
    public pyAmount ?: number,
    public mmyyStart ?: Date,
    public m1Amount ?: number,
    public m2Amount ?: number,
    public m3Amount ?: number,
    public m4Amount ?: number,
    public m5Amount ?: number,
    public m6Amount ?: number,
    public m7Amount ?: number,
    public m8Amount ?: number,
    public m9Amount ?: number,
    public m10Amount ?: number,
    public m11Amount ?: number,
    public m12Amount ?: number,
    public q1Amount ?: number,
    public q2Amount ?: number,
    public q3Amount ?: number,
    public q4Amount ?: number,
    public h1Amount ?: number,
    public h2Amount ?: number,
    public yrAmount ?: number,
    public finYear ?: string,
    public calYear ?: number,
    public salesMargin ?: number,
    public dsrRatio ?: number,
    public taxAssessmentYear ?: string,
    public totalSales ?: number,
    public totalPurchase ?: number,
    public grossProfilt ?: number,
    public netProfit ?: number,
    public taxFilingDate ?: string,
    public frozenFlag ?: boolean,
    public contextType ?: string,
    public contextId ?: string,
    public contextUid ?: string,
    public linkToType ?: string,
    public linkToId ?: string,
    public linkToUid ?: string,
    public version ?: number
  ) { }
}

export class PreCalValues {
  constructor(
    public netBusinessIncome ?: number,
    public incomePercentage ?: number,
    public eligibleFamilyIncome ?: number,
    public netIncomePostExpenses ?: number,
    public monthlySurplus ?: number,
    public debtServiceRatio ?: number,
    public emiEligibilityAsPerNetBusinessSurplus ?: number,
    public affordableEmi ?: number,
    public finalEmiEligibility ?: number,
    public actualEmi ?: number,
    public foir ?: number,
    public incomeAsPctOfGrossSales ?: number
  ) { }
}

export class CFASummary {
  constructor(
    public loanAmountProposed ?: number,
    public emiAmount ?: number,
    public businessIncomes ?: BusinessIncome[],
    public businessExpenses ?: Expense[],
    public totalBusinessExpense ?: number,
    public additionalIncomes ?: Expense[],
    public totalAdditionalIncome ?: number,
    public personalExpenses ?: Expense[],
    public totalPersonalExpense ?: number,
    public liabilityRepayments ?: Repayment[],
    public totalLiabilityRepayment ?: number,
  ) { }
}

export class Expense {
  constructor(
    public description ?: string,
    public familyMember ?: string,
    public amount ?: number
  ) { }
}

export class Repayment {
  constructor(
    public lendingInstitution ?: string,
    public product ?: string,
    public amount ?: number,
    public emi ?: number,
    public liabilityType ?: string
  ) { }
}

export class FiancialYearsAndMonthsInfo {
  constructor(
    public assesementYears ?: string[],
    public recentMonths ?: string[]
  ) { }
}

export class FamilyFinance {
  constructor(
    public uid ?: string,
    public version ?: number,
    public category ?: string,
    public familyMember ?: string,
    public incomeSource ?: string,
    public expenseType ?: string,
    public frequency ?: string,
    public pmAmount ?: number,
    public pqAmount ?: number,
    public phAmount ?: number,
    public pyAmount ?: number,
    public mmyyStart ?: Date,
    public m1Amount ?: number,
    public m2Amount ?: number,
    public m3Amount ?: number,
    public m4Amount ?: number,
    public m5Amount ?: number,
    public m6Amount ?: number,
    public m7Amount ?: number,
    public m8Amount ?: number,
    public m9Amount ?: number,
    public m10Amount ?: number,
    public m11Amount ?: number,
    public m12Amount ?: number,
    public q1Amount ?: number,
    public q2Amount ?: number,
    public q3Amount ?: number,
    public q4Amount ?: number,
    public h1Amount ?: number,
    public h2Amount ?: number,
    public yrAmount ?: number,
    public finYear ?: string,
    public calYear ?: number,
    public fileFolderUid ?: string,
    public frozenFlag ?: boolean,
    public contextType ?: string,
    public contextId ?: string,
    public contextUid ?: string,
    public linkToType ?: string,
    public linkToId ?: string,
    public linkToUid ?: string,
    public description ?: string
  ) { }
}

export class BusinessExpense {
  constructor(
    public uid ?: string,
    public version ?: number,
    public expenseType ?: string,
    public expenseDescription ?: string,
    public frequency ?: string,
    public pmAmount ?: number,
    public pqAmount ?: number,
    public phAmount ?: number,
    public pyAmount ?: number,
    public mmyyStart ?: Date,
    public m1Amount ?: number,
    public m2Amount ?: number,
    public m3Amount ?: number,
    public m4Amount ?: number,
    public m5Amount ?: number,
    public m6Amount ?: number,
    public m7Amount ?: number,
    public m8Amount ?: number,
    public m9Amount ?: number,
    public m10Amount ?: number,
    public m11Amount ?: number,
    public m12Amount ?: number,
    public q1Amount ?: number,
    public q2Amount ?: number,
    public q3Amount ?: number,
    public q4Amount ?: number,
    public h1Amount ?: number,
    public h2Amount ?: number,
    public yrAmount ?: number,
    public finYear ?: string,
    public calYear ?: number,
    public frozenFlag ?: boolean,
    public contextType ?: string,
    public contextId ?: string,
    public contextUid ?: string,
    public linkToType ?: string,
    public linkToId ?: string,
    public linkToUid ?: string
  ) { }
}