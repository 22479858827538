import { animate, state, style, transition, trigger} from '@angular/animations';
import { AfterViewInit, Component, OnDestroy, OnInit,ChangeDetectorRef, ViewChild ,ElementRef} from '@angular/core';
import { MediaChange, MediaObserver } from '@angular/flex-layout';
import { MatSnackBar } from '@angular/material';
import { ActivatedRoute ,Router} from '@angular/router';
import { BusinessEntity } from '@app/leads/leads.model';
import { Note } from '@app/note/note.model';
import { NoteService } from '@app/note/note.service';
import { ReportLink } from '@app/reports/reports.model';
import { ReportService } from '@app/reports/reports.service';
import { Task } from '@app/tasks/task.model';
import { TaskService } from '@app/tasks/task.service';
import { IgFileService } from '@ig-core/form/igFile.service';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import { Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ApplicationService } from '../application.service';
import { ApplicationAccessSpecifier, ApplicationUpdateDTO, LoanApplication, LoanProduct, Person, RecentApplicationsLocalDataComposite } from '../applications.model';
import { NameValueDto } from '@app/loan-od-accounts/name-value-dto';
import { ReferenceCodeService } from '@app/admin/reference-code/reference-code.service';
import { BranchService } from '@app/admin/branch/branch.service';
import { ThrowStmt } from '@angular/compiler';
import { SecureStorageService} from '../../../../projects/ig-core/src/lib/authentication/secureStorage.service';
import { ImagePreviewDialogService } from '@app/utils/image-preview-dialog/image-preview-dialog.service';
import { RecentOpenedApplicationsService } from '.././recent-opened-applications.service';
import { User } from '@app/admin/user/user.model';
import { UserService } from '@app/admin/user/user.service';
import { ActionItemService } from '../action-item.service';
import { ActionItem } from './action-item.model';
import { ActionItemsComponent } from './decisions/action-items/action-items.component';
import { LtvDeviationDTO } from './collateral/collateralSummary/collateral-summary.model';
import { FormControl, Validators } from '@angular/forms';
import { ColletralSummaryComponent } from './collateral/collateralSummary/collateral-summary.component';
import { Branch } from '@app/admin/branch/branch.model';
import { ProductMasterService } from '@app/admin/product-master/product-master.service';
import { LoanProposedComponent } from './loanInformation/loanProposed/loan-proposed.component';
import { ReferenceCode } from '@app/admin/reference-code/reference-code.model';
@Component({
    selector: 'eng-application-details',
    templateUrl: 'application-details.template.html',
    styleUrls: ['application-details.styles.scss'],
    animations :[
      trigger('showHideSidenav', [
        state('show', style({width: '22vw'})),
        state('hide', style({width: '0px'})),
        transition('show <=> hide', animate('0.5s')),
      ]),
      trigger('shrinkExpandSidenavContent', [
        state('shrink', style({'margin-right': '22vw'})),
        state('expand', style({'margin-right': '0px'})),
        transition('shrink <=> expand', animate('0.5s')),
      ])
    ]
})
export class ApplicationDetailsComponent implements OnInit, AfterViewInit, OnDestroy {
    selectedMenu: string = '';
    menuItem:string;
    currentScreenWidth: string = '';
    isSmallScreen: boolean = false;
    flexMediaWatcher: Subscription;
    listOfFeatures: string[];
    
    application: LoanApplication;
    recentOpenedApplications:Array<RecentApplicationsLocalDataComposite>=[];

    business: BusinessEntity;
    businessImage: any;
    imageLoaded: boolean;

    applicants: Person[];
    applicant: Person;
    coApplicants: Person[] = [];

    selectedPerson: Person;
    
    followUps: Task[];
    
    notes: Note[];
    noteContent : string = '';
    actionItem: Task = {};
    actionItemContent: string;

    leadSources: NameValueDto[] = [];
    userBranches: NameValueDto[];
    userRoles: any;
    result:ActionItem;

    applicationDecision: string;
    applicationDecisionRemarks: string;
    unlockRemarks:string;

    reportLinks: ReportLink[];

    applicationAccessSpecifiers: ApplicationAccessSpecifier[];
    fullApplicationAccessSpecifier: ApplicationAccessSpecifier;
    allowAccess: boolean;
    userRoleAllowed:boolean;
    returnStages: string[];
    isReturnStage: boolean;
    otpCardAllowed:boolean=false
    returnStage: string;
    otp:number;
    decisionErrors:any;
    personImageDetails:any;
    applicationDetailsCard:number=1;
    decisionCard:number=2;
    errorCard:number=3;
    otpCard:number=4;
    unlockCard:number=5;
    recentCard:number=6
    noteCard:number=7;
    roCard:number=8;
    actionItemCard: number=9;
    rejectReasonCard: number=10;
    ltvDeviationCard: number=11;
    branchROs : User[] = [];
    currentRo:User
    SelectedRO: any;
    loginUser:any
    assignToRole: string
    dueDate: Date
    // minDate variable is used not to show previous dates in due date and revised due date
    minDate: Date;

    isReject: boolean;
    rejectReasons: any;
    rejectReason: string[] = [];
    applicationRejectReasons: any;
    checklistComposite: any;
    selectedOpsChecklistSection: any;
    showChecklistAssit: boolean;
    sectionIndexAndCardStatus: any;

    ltvPercent: string = ''
    ltvRemarks: string = ''
    ltvDeviationDto: LtvDeviationDTO = {}
    ltvPercentControl: FormControl;

    @ViewChild(ActionItemsComponent) childComponent: ActionItemsComponent;
    @ViewChild(ColletralSummaryComponent) collateral: ColletralSummaryComponent;
    @ViewChild(LoanProposedComponent) loanProposed: LoanProposedComponent;
    checklistSectionReferenceCode: any;
    currentSectionIndex: number = 0;
    totalSections: number = 0;

    // update Ro or Branch variables
    updateApplication: ApplicationUpdateDTO = {};
    selectedBranch: any;
    selectedLeadSource: any;
    // choose product variables
    showChooseProduct: boolean;  // this variable is used to show or hide choose product in sidecard 
    selectedAmount: number;
    // across all the product these are min and max amount to show in the sidecard slider
    minAmountAcrossProducts: number;
    maxAmountAcrossProducts: number;
    // existingProduct this is already selected existing product
    existingProduct: LoanProduct;
    allProducts: any;
    // matchingProducts is product which are matching for the given amount range and loanType
    matchingProducts: any;
    // we are sorting products in minmum to maximum amount range before showing matching product.
    sortedProducts: any;
    // newSelectedProduct this is product which we have selected for submition
    newSelectedProduct: any;
    // loanType is dropdown shown in choose product, this variable is used to sort product based on loanType
    loanType: any;
    productTypes: ReferenceCode[];
    cfaFinalized: boolean;
    // sortedLoanTypeProducts this contain products based on selected loanType.
    sortedLoanTypeProducts: any;

    constructor(private activatedRoute: ActivatedRoute,
      private router: Router,
        private media: MediaObserver,
        private applicationService: ApplicationService,
        private fileService: IgFileService,
        private taskService: TaskService,
        private noteService: NoteService,
        private reportService: ReportService,
        private branchService: BranchService,
        private referenceCodeService: ReferenceCodeService,
        private secureStorageService: SecureStorageService,
        private imagePreviewDialogService:ImagePreviewDialogService,
        private cd: ChangeDetectorRef,
        private _snackbar: MatSnackBar,
        private userService:UserService,
        private recentOpenedApplicationsService:RecentOpenedApplicationsService,
        private actionItemService:ActionItemService,
        private productMasterService:ProductMasterService) {
      this.activatedRoute.data
        .pipe(takeUntil(componentDestroyed(this)))
        .subscribe(response => {
          this.application = response.application.body.applicationDTO;
          this.business = response.application.body.businessEntityDTO;
          this.applicants = response.application.body.personDTO;
          this.applyApplicationAccessModifiers(this.application.uid);
          if(this.application.rejectReason !== null && this.application.rejectReason !== undefined){
            this.applicationRejectReasons = this.application.rejectReason.split(',')
            this.rejectReason = this.applicationRejectReasons
            // on click of save or on open of application if reject reason present then we are showing the rejectReason card in first position, that is below quick icon
            this.onClickCardIcon('rejectReasonCard')
          }
        },error => {
          this._snackbar.open("Failed to open application, " + error, "Close", {
            duration: 2000,
          })
          console.log(error);
      });
      this.flexMediaWatcher = media.media$.subscribe((change: MediaChange) => {
        if(change.mqAlias !== this.currentScreenWidth) {
          this.currentScreenWidth = change.mqAlias;
          this.isSmallScreen = media.isActive('lt-md');
        }
      });
      this.minDate = new Date();
      let numericValidator = Validators.pattern('^\\d*\\.?\\d+$');
      this.ltvPercentControl = new FormControl('', [Validators.maxLength(5),Validators.min(0),Validators.max(100),numericValidator])
    }

    ngOnInit() {
      this.showChooseProduct = false;
      this.getRefCodes()
      this.getUserBranches()
      this.referenceCodeService.getShortRefCodes('sourcing_code')
        .subscribe((response: any) => {
          this.leadSources = response.sourcing_code;
      });
      
      //this.getFollowUps(this.application.uid);
      this.getNotes(this.application.uid);

      this.applicants.forEach(applicant => {
        if(applicant.dateOfBirth){
          applicant.age = Math.floor((new Date().getTime() - 
            new Date(applicant.dateOfBirth).getTime()) / (1000 * 60 * 60 * 24 * 365.25));
        }
      });

      this.applicant = this.applicants.find(applicant => applicant.partyPlay === 'applicant');
      this.coApplicants = this.applicants.filter(applicant => 
        applicant.partyPlay === 'coapplicant');
        
      this.moveGuarantorToEnd()
      
      this.getReportLinks();
      this.checkLoginUserRole();
    
      this.recentOpenedApplicationsService.entityCreatedDate(this.application.applicationDate)
      let recentAplication=this.application
      recentAplication["applicantName"]=this.applicant.firstName +' '+ this.applicant.lastName
      this.recentOpenedApplicationsService.addRecentApplication(recentAplication)
      this.recentOpenedApplications=this.recentOpenedApplicationsService.getRecentOpenedApplications()
      this.loginUser = this.secureStorageService.secureSessionStorage.getItem("logInUserDetails")
      if(this.loginUser.authorities.includes('ROLE_ADMIN')){
      this.getROsForBranch(this.application.branchCode)
      }
      this.getRoles();
    }

    ngAfterViewInit() {
      this.loadBusinessImage(this.business.profilePicFileId);
     
    }

    getRefCodes() {
      this.referenceCodeService.getRefCodesForClassifier('reject_reason').subscribe((response) => {
        this.rejectReasons = response.sort((a, b) => a.name.localeCompare(b.name));
      });

      // this is to get what are the features need to shown on the left expansion panel.
      // we will check if the particular name is present in listOfFeatures then we will show in the expansion panel else we will dissable particular feature
      this.referenceCodeService.getFeatureSet().subscribe(response =>{
        this.listOfFeatures = response.body.map(feature => feature.toLocaleLowerCase());
      })

      this.referenceCodeService.getRefCodesForClassifier('loan_type').subscribe((response: any) => {
        this.productTypes = response
      })

    }

    // to show guarantor at the end we are calling this method, findIndex will find the index of matching coApplicant
    // and it will remove coApplicant from that index. [0] is taking first element of the spliced list and pushing to the last position of the list.
    // If no matching found it will return -1, then we are returning the same list.
    moveGuarantorToEnd(){
      let guarantorIndex = this.coApplicants.findIndex(coApplicant => coApplicant.uid === this.application.guarantorUid);

      if (guarantorIndex !== -1) {
        let guarantor = this.coApplicants.splice(guarantorIndex, 1)[0];
        this.coApplicants.push(guarantor);
      }
    }

    /* Any further computations required for ceratin fields can be done here */
    processApplicationData() {
      this.application.product = !!this.application.productCodeSanc ?
        this.application.productCodeSanc : (!!this.application.productCodeProp ?
          this.application.productCodeProp : this.application.productCodeReq);
        
      /* Amount, tenure, interest all have same checks.
          amount sanc ? sanc : (amount prop ? prop : req. */
      this.application.amount = (this.application.amountSanc
        && this.application.amountSanc > 0) ? this.application.amountSanc :
          (this.application.amountProp && this.application.amountProp > 0 ?
            this.application.amountProp : this.application.amountReq);
        
      this.application.tenure = (this.application.amountSanc
        && this.application.amountSanc > 0) ? this.application.tenureSanc :
          (this.application.amountProp && this.application.amountProp > 0 ?
            this.application.tenureProp : this.application.tenureReq);

        this.application.interest = (this.application.amountSanc
          && this.application.amountSanc > 0) ? this.application.interestSanc :
            (this.application.amountProp && this.application.amountProp > 0 ?
              this.application.interestProp : this.application.interestReq);
    }

    applyApplicationAccessModifiers(applicationUid: string) {
      this.applicationService.getApplicationAccessModifiers(applicationUid)
        .subscribe((response) => {
          this.applicationAccessSpecifiers = response.body;
          
          this.fullApplicationAccessSpecifier = this.applicationAccessSpecifiers
            .find(accessSpecifier => accessSpecifier.category === 'fullApplication');
          this.allowAccess = this.fullApplicationAccessSpecifier.allowAccess;
          this.applicationService.allowAccess = this.allowAccess;
          let userRoleAllowed =this.applicationAccessSpecifiers
          .find(accessSpecifier => accessSpecifier.category === 'userRoleAllowed');
          if(userRoleAllowed){
          this.userRoleAllowed =userRoleAllowed.allowAccess
          }
         // this.applicationAccessSpecifiers.shift()
          //this.applicationAccessSpecifiers.shift()
          this.selectedMenu = this.applicationAccessSpecifiers.find(menu=>((menu.allowAccess == true && menu.category != "fullApplication" && menu.category != "userRoleAllowed"))).category;
          if(this.selectedMenu == 'DOCUMENTATION'){
            this.updateSelectedMenu('')
            this.router.navigate(['/applications',this.application.uid,'reports'],{ queryParams: { contextUid: this.application.uid, category: "document"} });
          }
      });
    }

    getReportLinks() {
      let category="document";
      this.reportService.getReportLinks(category).subscribe((response) => {
        this.reportLinks = response.body;
        this.reportLinks.forEach(reportLink => {
          reportLink.contextType = 'application';
          reportLink.contextUid = this.application.uid;
        });
      });
    }

    loadBusinessImage(folderUid: string) {
      if(!folderUid) {
        this.businessImage = null;
      } else {
        //Fetch all FileDtos stored in the folder
        this.fileService.getAllFilesFromFolder(folderUid).subscribe((response) => {
          if(response && response.body && response.body.length > 0) {
            this.personImageDetails=response.body
            //If response received then use fileId from response's first object to stream
            this.fileService.fileStreamById(response.body[0].fileId,
                "true").subscribe(image => {
              let reader = new FileReader();
              reader.addEventListener("load", () => {
                //"businessImage" is used in img src attr
                this.businessImage = reader.result;
                this.imageLoaded = true;
              }, false);
              if(image) reader.readAsDataURL(image.data);
            });
          } else {
            this.businessImage = undefined;
            this.imageLoaded = false;
          }
        });
      }
    }

    getFollowUps(applicationUid) {
      this.taskService.getByLink('application', applicationUid)
        .subscribe((response) => {
          this.followUps = response.body;
      });
    }

    getNotes(applicationUid) {
      this.noteService.getNote({linkToType: 'application',
        linkToUid: applicationUid}).subscribe((response) => {
          this.notes = response.body;
      });
    }

    decisionChange() {
      if(this.applicationDecision === 'return') {
        this.rejectReason = [];
        this.isReturnStage = true;
        this.applicationService.getWorkflowReturnStages(this.application.uid)
          .subscribe((response) => {
            this.returnStages = response.body;
        });
      }
      else this.isReturnStage = false;

      if(this.applicationDecision === 'reject') {
        this.returnStage = null;
        this.isReject = true;
      }
      else this.isReject = false;
    }

    updateSelectedMenu(selectedMenu) {
        this.selectedMenu = selectedMenu;
        let currentUrl="/applications/"+this.application.uid
        if(this.selectedMenu != '' && this.router.url != currentUrl){
          this.router.navigate(['/applications',this.application.uid])
        }
        //this.getFollowUps(this.application.uid);
    }

    setSelectedPerson(coApplicant ?: Person) {
      if(coApplicant) this.selectedPerson = coApplicant;
      else this.selectedPerson = this.applicant;
    }

    saveDecision() {
      if(this.applicationDecision && this.applicationDecision !== '') {
      // this code is for rejectReason, this should not be deleted. This block of code need to be uncommented while releasing rejectReason

        if(this.applicationDecision === 'reject'){
          // reject reasons are mandatory atleast user need to select one reject reason
          if(this.rejectReason.length === 0){
            this._snackbar.open("Please select one or more reject reason" , "close",{
              duration: 5000
            })
            return;
          }
        }

        this.applicationService.saveDecision(this.application.uid,
            this.application.trackingId, this.applicationDecision,
            this.returnStage,this.rejectReason, this.applicationDecisionRemarks, this.application.currentWorkflowStage).toPromise().then(
          (_success) => {
            this._snackbar.open(_success.body.status + ": "
                + _success.body.message, "Close" ,{
              duration:10000,
            });
            this.applyApplicationAccessModifiers(this.application.uid);
           this.refreshApplicationData()
           this.reloadCurrentRoute()
          }, 
          (_failure) => {
            let errormesg =[]
            errormesg.push("Application decision update failed.")
            this.decisionErrors=[]
            if(_failure.params){
              errormesg.push(_failure.message)
              let keys =Object.keys(_failure.params)
              keys.forEach((element,index) => {
                this.decisionErrors.push(_failure.params['param'+index])
              });
              this.onClickCardIcon('errorCard')
            }else{
              errormesg.push(_failure)
            }
          
            this.applicationService.displayErrorMessages(errormesg);
            this.applicationDecision = '';
          }
        );
      }
    }

    saveNote() {
      if (this.noteContent && this.noteContent.trim() !== "") {
        let note : Note = {
          linkToUid : this.application.uid,
          linkToType : 'application',
          content : this.noteContent
        }
        this.noteService.saveNote(note).toPromise().then(
          (success) => {
            this._snackbar.open("Note updated sucessfully", "Close" ,{
              duration:2000,
            });
            this.noteContent = '';
            this.getNotes(this.application.uid);
          },
          (failure) => {
            let errormesg =[]
            errormesg.push("Note update failed.")
            errormesg.push(failure)
            this.applicationService.displayErrorMessages(errormesg);
            console.log(failure);
          }
        )
      }
    }

    // Obsolete. Not used anymore
    /* initiateWorkflow() {
      this.applicationService.initiateWorkflow(this.application.uid).toPromise().then(
        (_success) => {
          this._snackbar.open(_success.status + ": " + _success.message, "Close", {
            duration: 15000,
          });
        },(failure) => {
          this._snackbar.open("Failed to initiate workflow", "Close", {
            duration: 15000,
          });
          console.log(failure);
        }
      );
    } */

    showReportCriteria(reportLinkUid: string) {
      alert(reportLinkUid);
    }
//this function will call to get basic application data 
// to refresh "this.application" values after particular action eg: after decision save like proceed in sidenav
    refreshApplicationData() {
      this.applicationService.getBasicApplicationInfo(
        this.application.uid, 'ApplicationOnly').subscribe((response) => {
          this.application = response.body.applicationDTO;
          this.applyApplicationAccessModifiers(this.application.uid);
      });
    }

    ngOnDestroy(): void {
    }

    getUserBranches() {
      this.branchService.getBranchesForUser().subscribe((response: any) => {
        if (response) {
          this.userBranches = response.body;
        }
      });
    }

      //this function is used to unlock the application
      unlockApplication(){
        let applicationUnlockRequest={
          "remarks": this.unlockRemarks,
          "responseMessage": null
        }
        this.applicationService.unLockApplication(this.application.uid,applicationUnlockRequest).subscribe((response) =>{
          this.refreshApplicationData()
          
          this._snackbar.open("Application unlocked successfully " , "Close", {
            duration: 2000,
          })
        },error =>{
          this._snackbar.open("Application unlocking failed, " + error, "Close", {
            duration: 2000,
          })
        })
      }


      applicationDataChangedHandler(application) {
        this.application = application;
        console.log(application);
      }

      sendOTP(){
        let request ={
          "requestBy": this.secureStorageService.secureSessionStorage.getItem("logInUserDetails").login,
          "requestNode":"1",
          "requestApp":"webapp",
          "requestLat":"0.00",
          "requestLng":"0.00"
        }
        this.applicationService.getApplicationOTP(this.application.uid,request).subscribe((response)=>{
          if(response.body.status =="success"){
            let errormesg =[]
            errormesg.push("sent OTP to your registered mobile number successfully")
            errormesg.push(response.body.message)
            this.applicationService.displayErrorMessages(errormesg);
          }else{
            let errormesg =[]
            errormesg.push(response.body.message)
            this.applicationService.displayErrorMessages(errormesg);
          }
        }, (failure) => {
          let errormesg =[]
          errormesg.push("Sending OTP failed")
          errormesg.push(failure)
          this.applicationService.displayErrorMessages(errormesg);
        });
      }

      SubmitOTP(){
        if(this.otp){
        let request ={
          "otp":this.otp,
          "usedBy":this.secureStorageService.secureSessionStorage.getItem("logInUserDetails").login,
          "usedNode":"1",
          "usedApp":"webapp",
          "usedLat":"0.00",
          "usedLng":"0.00"
        }
        this.applicationService.getOTPConfirmation(this.application.uid,request).subscribe((response)=>{
          if(response.body.status =="success"){
            let errormesg =[]
            errormesg.push("sent OTP to your registered mobile number successfully")
            errormesg.push(response.body.message)
            this.applicationService.displayErrorMessages(errormesg);
            this.refreshApplicationData()
          }else{
            let errormesg =[]
            errormesg.push(response.body.message)
            this.applicationService.displayErrorMessages(errormesg);
          }
        })
      }else{
        this._snackbar.open("Please enter OTP" , "Close", {
          duration: 2000,
        })
      }

    }

  checkLoginUserRole() {
    let userRoles = this.secureStorageService.secureSessionStorage.getItem("logInUserDetails").authorities
    if (userRoles.includes("ROLE_ADMIN") || userRoles.includes("BM")) {
      this.otpCardAllowed = true
    }
  }

  reloadCurrentRoute() {
    let currentUrl = this.router.url;
    let testURL='/'
    this.router.navigateByUrl(testURL, {skipLocationChange: true}).then(() => {
        this.router.navigate([currentUrl]);
    });
}

    //this function will trigger onclicking of image,will open up a mat-dailog
    openImageViewElement() {
      let fileDetails=  {
        folderInfo : {},
        fileInfo :  [],
      }
      fileDetails.fileInfo=this.personImageDetails
     this.imagePreviewDialogService.open({ "images": fileDetails})
   }

  onClickCardIcon(eventName) {
    switch (eventName) {
      case 'applicationDetailsCard':
        this.applicationDetailsCard=1
        this.decisionCard = this.applicationDetailsCard+1
        this.errorCard =  this.applicationDetailsCard+2
        this.otpCard =  this.applicationDetailsCard+3
        this.unlockCard =  this.applicationDetailsCard+4
        this.recentCard = this.applicationDetailsCard+5
        this.noteCard =  this.applicationDetailsCard+6
        this.actionItemCard = this.applicationDetailsCard+7
        this.rejectReasonCard = this.applicationDetailsCard+8
        this.ltvDeviationCard = this.applicationDetailsCard+9
        this.roCard=11;
        break;
        case 'decisionCard':
        this.decisionCard = 1
        this.applicationDetailsCard=this.decisionCard+1
        this.errorCard =  this.decisionCard+2
        this.otpCard =  this.decisionCard+3
        this.unlockCard =  this.decisionCard+4
        this.recentCard = this.decisionCard+5
        this.noteCard =  this.decisionCard+6
        this.actionItemCard = this.decisionCard+7
        this.rejectReasonCard = this.decisionCard+8
        this.ltvDeviationCard = this.decisionCard+9
        this.roCard=11;
        break;
        case 'errorCard':
          this.errorCard=1
          this.applicationDetailsCard=this.errorCard+1
        this.decisionCard = this.errorCard+2
        this.otpCard =  this.errorCard+3
        this.unlockCard =  this.errorCard+4
        this.recentCard = this.errorCard+5
        this.noteCard =  this.errorCard+6
        this.actionItemCard = this.errorCard+7
        this.rejectReasonCard = this.errorCard+8
        this.ltvDeviationCard = this.errorCard+9
        this.roCard=11;
          break;
          case 'otpCard':
            this.otpCard = 1
            this.applicationDetailsCard=this.otpCard+1
          this.decisionCard = this.otpCard+2
          this.errorCard =  this.otpCard+3
          this.unlockCard =  this.otpCard+4
          this.recentCard = this.otpCard+5
          this.noteCard =  this.otpCard+6
          this.actionItemCard = this.otpCard+7
          this.rejectReasonCard = this.otpCard+8
          this.ltvDeviationCard = this.otpCard+9
          this.roCard=11;
            break;
      case 'unlockCard':
        this.unlockCard = 1
        this.applicationDetailsCard=this.unlockCard+1
        this.decisionCard = this.unlockCard+2
        this.errorCard =  this.unlockCard+3
        this.otpCard =  this.unlockCard+4
        this.recentCard = this.unlockCard+5
        this.noteCard =  this.unlockCard+6
        this.actionItemCard = this.unlockCard+7
        this.rejectReasonCard = this.unlockCard+8
        this.ltvDeviationCard = this.unlockCard+9
        this.roCard=11;
        break;
        case 'recentCard':
        this.recentCard = 1
        this.applicationDetailsCard=this.recentCard+1
        this.decisionCard = this.recentCard+2
        this.errorCard =  this.recentCard+3
        this.unlockCard =  this.recentCard+4
        this.otpCard =  this.recentCard+5
        this.noteCard =  this.recentCard+6
        this.actionItemCard = this.recentCard+7
        this.rejectReasonCard = this.recentCard+8
        this.ltvDeviationCard = this.recentCard+9
        this.roCard=11;
        break;
      case 'noteCard':
        this.noteCard = 1
        this.applicationDetailsCard=this.noteCard+1
        this.decisionCard = this.noteCard+2
        this.errorCard =  this.noteCard+3
        this.unlockCard =  this.noteCard+4
        this.otpCard =  this.noteCard+5
        this.recentCard = this.noteCard+6
        this.actionItemCard = this.noteCard+7
        this.rejectReasonCard = this.noteCard+8
        this.ltvDeviationCard = this.noteCard+9
        this.roCard=11;
        break;
        case 'roCard':
        this.roCard = 1
        this.applicationDetailsCard=2
        this.decisionCard = 3
        this.errorCard =  4
        this.otpCard =  5
        this.unlockCard =  6
        this.recentCard = 7
        this.noteCard = 8
        this.actionItemCard = 9
        this.rejectReasonCard = 10
        this.ltvDeviationCard = 11
        break;
      case 'actionItemCard':
        this.actionItemCard =1
        this.applicationDetailsCard = this.actionItemCard+1
        this.decisionCard = this.actionItemCard+2
        this.errorCard =  this.actionItemCard+3
        this.otpCard =  this.actionItemCard+4
        this.unlockCard =  this.actionItemCard+5
        this.recentCard = this.actionItemCard+6
        this.noteCard =  this.actionItemCard+7
        this.rejectReasonCard = this.actionItemCard+8
        this.ltvDeviationCard = this.actionItemCard+9
        this.roCard=11;
        break;
      case 'rejectReasonCard':
        this.rejectReasonCard =1
        this.applicationDetailsCard = this.rejectReasonCard+1
        this.decisionCard = this.rejectReasonCard+2
        this.errorCard =  this.rejectReasonCard+3
        this.otpCard =  this.rejectReasonCard+4
        this.unlockCard =  this.rejectReasonCard+5
        this.recentCard = this.rejectReasonCard+6
        this.noteCard =  this.rejectReasonCard+7
        this.actionItemCard = this.rejectReasonCard+8
        this.ltvDeviationCard = this.rejectReasonCard+9
        this.roCard=11;
        break;
      default:
        console.log("No such day exists!");
        break;
    }
    this.cd.detectChanges()
  }

  goToRecentApplication(goToApplication){
  this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
    this.router.navigate(['/applications',goToApplication.application.uid]);
}); 
  }

    // Fetch 'RO' users for a given branch code.
    getROsForBranch(branchCode) {
      this.userService.getUsersForBranchByRole(branchCode, "RO")
        .subscribe(response => {
          let roArrayList =[]
          let ros =response.body
          ros.forEach( ro=> {
            if(ro.activated){
              roArrayList.push(ro)
            }
            if(ro.id == this.application.salesPersonUid){
              this.currentRo =ro
            }
          });
          this.branchROs = roArrayList
      });
    }

    updateProperties(){
      if (this.SelectedRO || this.selectedBranch || this.selectedLeadSource) {
        this.updateApplication.salesPersonId = this.SelectedRO ? this.SelectedRO : null;
        this.updateApplication.branchCode = this.selectedBranch ? this.selectedBranch : null;
        this.updateApplication.opportunitySource = this.selectedLeadSource ? this.selectedLeadSource : null;
        this.applicationService.updateApplicationRO(this.application.uid,this.updateApplication).subscribe(res=>{
          this._snackbar.open("Updated successfully" , "Close", {
            duration: 6000,
          })
          this.branchROs.forEach( ro=> {
            if(ro.id == res.body.salesPersonUid){
              this.currentRo = ro
            }
          });
          this.updateApplication = new ApplicationUpdateDTO;
          this.reloadCurrentRoute() 
        })
      }else{
        this._snackbar.open("Select any Ro, Branch or Lead Source ", "Close", {
          duration: 6000,
        })
      }
    }

    // this reloadNotes is called when we add fill account from server side note is created to update not we are calling this api
    reloadNotes(){
      this.getNotes(this.application.uid);
    }

    addActionItem(){
      if (!this.actionItemContent || this.actionItemContent.trim() === "") {
        this._snackbar.open("Action item failed, Description cannot be empty. Please add description." , "close", {
          duration: 5000
        });
        return;
      }

      this.actionItem.assignedTo = this.assignToRole
      this.actionItem.plannedDueDate = this.dueDate
      this.actionItem.description = this.actionItemContent
      this.actionItemService.saveActionItems(this.application.uid, this.actionItem).subscribe(response =>{
        let result = response;
        this._snackbar.open("Action item added successfully" , "close", {
          duration: 5000
        });
        this.assignToRole = '';
        this.actionItemContent = '';
        this.dueDate = null;
        // this condition is checked for if the user selected actionItems then only we need to refresh the component and show new added date
        // or else when we click on actionItems then only getActionItems will be called and it will show the action items present for that particular application
        if(this.selectedMenu === 'ACTION-ITEMS'){
        this.childComponent.getActionItems();
        }
      },(failure =>{
        this._snackbar.open("Action item failed, " + failure , "close", {
          duration: 5000
        });
      }))

    }

    saveLtvDeviationPercentage(){
      if(!this.ltvPercent || this.ltvPercent.trim() === ""){
        this._snackbar.open("Ltv deviation save failed, percentage cannot be empty. Please enter percentage." , "close", {
          duration: 5000
        });
        return;
      }

      if(this.ltvPercentControl.valid){
        this.ltvDeviationDto.deviationPercentage = this.ltvPercent
        this.ltvDeviationDto.remarks = this.ltvRemarks
        this.applicationService.saveLtvDeviationPercentage(this.application.uid,this.ltvDeviationDto).subscribe(response =>{
          this._snackbar.open("LTV deviation percentage changed successfully" , "close", {
            duration: 5000
          }); 
          this.ltvPercent = ''
          this.ltvRemarks = ''
          // this method is called to update the ltv deviation notes entry
          this.getNotes(this.application.uid)
          // this condition is checked for if the user selected collateralSummary then we need to refresh the component and show updated percentage
          if(this.selectedMenu === 'COLL-SUMMARY'){
            this.collateral.fetchCollateranSummary();
          }
        })
      }
    }

    // this function is to get roles for action item dropdown
    getRoles(){
      this.actionItemService.getAssignRolesToActionItems(this.application.uid).subscribe(response => {
        this.userRoles = response;
        this.userRoles.sort((a, b) => a.name.localeCompare(b.name));
      })
    }

    // here checklistComposit contains selectedOpsChecklistSection, userResponseField and checkListResponseTypes
    onChecklistAssistLaunch(checklistComposite){
      this.showChecklistAssit = true;
      this.checklistComposite = checklistComposite;
      // right now checklist is divided into 10 section,  out of 10 section this variable is used to determine which section is selected
      this.selectedOpsChecklistSection = checklistComposite.selectedOpsChecklistSection
      // this is checklist sections to display each checklist title names
      this.checklistSectionReferenceCode = checklistComposite.checklistSectionReferenceCode
      // this is index of particular section number on click of launch from main component this variable is used to display in checklist assist
      this.currentSectionIndex = checklistComposite.index
      // this is total number of checklisSection 
      this.totalSections = this.checklistSectionReferenceCode.length
      // this is used to send selected section code to main component and status of sidebar card opened or closed 
      this.setSelectedSectionIndexAndCardStatus()
    }

    saveChecklistItemResponse(event: any,checklistItem: any){
      checklistItem['response'+this.checklistComposite.userResponseField] = event.value
      this.recentOpenedApplicationsService.addChecklistItem(checklistItem,this.application.uid)
    }

    // on click of 'x' this method is called to close sidebar card
    closeChecklist(){
      this.showChecklistAssit = false;
      this.setSelectedSectionIndexAndCardStatus()
    }

    goToNextSection() {
      if (this.currentSectionIndex < this.totalSections - 1) {
        this.currentSectionIndex++;
      }
      this.setSelectedSectionIndexAndCardStatus()
    }
    
    goToPreviousSection() {
      if (this.currentSectionIndex > 0) {
        this.currentSectionIndex--;
      }
      this.setSelectedSectionIndexAndCardStatus()
    }

    // this method is used to send which section was opened and card status to checklistComponent
    setSelectedSectionIndexAndCardStatus(){
      this.sectionIndexAndCardStatus = {
        selectedIndex: this.currentSectionIndex,
        showCard: this.showChecklistAssit
      }
    }

    openChooseProduct(presentProduct){
      this.newSelectedProduct = ''
      this.selectedAmount = presentProduct.amount
      this.existingProduct = presentProduct.existingProduct
      this.loanType = this.existingProduct.productType;
      this.cfaFinalized = presentProduct.cfaStatus
      this.getAllProductList()
    }


    getAllProductList(){
      // if allProducts is present then products already fetched, no need to fetch again, hence we are only filtering products on given amount
      if(this.allProducts && this.allProducts.length !== 0){
        this.showChooseProduct = true;
        // Showing selected amount is amount is not selected we are assuming selectedAmount as minAmountAcrossProducts
        this.showSelectedAmountAndProducts(this.selectedAmount ? this.selectedAmount : this.minAmountAcrossProducts, this.loanType)
        return;
      }

      this.productMasterService.getProductsByBranchCode(this.application.branchCode).subscribe(response =>{
        this.allProducts = response.body
        this.showChooseProduct = true;
        // if product set is set as "Empty Productset" for particular branch there there will be no products for that branch,
        // hence we are displaying message to set productset so that products will appear for that particular branch
        if(this.allProducts.length === 0){
          this._snackbar.open("No product set found for this particular branch", "close",{
            duration: 5000
          })
          this.showChooseProduct = false;
          return;
        }
        // first we are filtering out inactive and flagged products, then sorting products by maximum amount in descending order 
        // we are showing this maximum amount in the slider maxAmount.
        this.allProducts = this.allProducts.filter(product => product.processFlag && product.changeFlag);

        this.sortedProducts = this.allProducts.sort((a, b) => b.maxAmount - a.maxAmount);
        this.maxAmountAcrossProducts = this.sortedProducts[0].maxAmount

        //getting the minimum amount across products and this is set as minAmount in the slider
        let minAmountAcrossProduct
        minAmountAcrossProduct = this.allProducts.sort((a, b) => a.minAmount - b.minAmount);
        this.minAmountAcrossProducts = minAmountAcrossProduct[0].minAmount
        // showSelectedAmountAndProducts this will show the selected amount above the slider
        // filterProductsOnAmount is used to filter products based on selected amount and loan type,
        this.showSelectedAmountAndProducts(this.selectedAmount ? this.selectedAmount : this.minAmountAcrossProducts, this.loanType)
      })
    }
    
  showSelectedAmountAndProducts(value, loanType){
    this.loanType = loanType;
    this.sortedLoanTypeProducts = this.sortedProducts.filter(product => product.productType == loanType);

    this.filterProductsOnAmount(value);
  }

  filterProductsOnAmount(selectedAmount){
    this.selectedAmount = selectedAmount;
    if(this.loanType === null){
     this.matchingProducts = this.sortedProducts.filter(product => selectedAmount >= product.minAmount && selectedAmount <= product.maxAmount);
    }else{
     this.matchingProducts = this.sortedLoanTypeProducts.filter(product => selectedAmount >= product.minAmount && selectedAmount <= product.maxAmount);
    }
  }

  closeChooseProductDialog(){
    this.showChooseProduct = false
  }

  selectedProduct(selectedProduct){
    if (this.newSelectedProduct === selectedProduct) {
      // Deselect if the clicked product is already selected
      this.newSelectedProduct = '';
    } else {
      // Single click, set the selected product
      this.newSelectedProduct = selectedProduct;
    }
  }

  submitSelectedProduct(){
    if(this.newSelectedProduct){
      this.showChooseProduct = false;
      // matchingProductsForProductTypeProp is used to send loanProduct to loan proposed screen instead of again calling api 
      let matchingLoanProductsForProductTypeProp;
      // here sortedLoanTypeProducts will have value if we select any loanType else if we select "ALL" then it will be empty,
      // hence we need to filter based on productType with sortedProduct list which has only active ones.
      if(this.sortedLoanTypeProducts.length !== 0){
        matchingLoanProductsForProductTypeProp = this.sortedLoanTypeProducts;
      }else{
        matchingLoanProductsForProductTypeProp = this.sortedProducts.filter(product => product.productType == this.newSelectedProduct.productType);
      }
      this.loanProposed.buildFormFromProduct(this.newSelectedProduct, this.selectedAmount,matchingLoanProductsForProductTypeProp)
    }
  }
}
